import { IInitialUserState } from 'interfaces/user.interface';

const initialUserState: IInitialUserState = {
  dbUser: null,
  company: null,
  isLoading: false,
  login: () => Promise.resolve(false),
  updateUser: () => Promise.resolve(false),
  updateCompanyData: () => Promise.resolve(false),
  updateUserAvatar: () => Promise.resolve(false),
  updateCompanyLogo: () => Promise.resolve(false),
  updateLanguage: () => Promise.resolve(false),
  updateSocialMedia: () => Promise.resolve(false),
  removeSocialMedia: () => Promise.resolve(false),
  selectCompanyAndUserSuperAdmin: () => Promise.resolve(false),
  selectCompanySuperAdmin: () => Promise.resolve(false),
};

export default initialUserState;
