import * as api from 'api/endpoints/mainContacts/mainContacts.api';
import * as MainContactsTypes from './mainContacts.types';
import { mainContactError } from 'utils/errorCodes';
import { notificationError } from 'views/components/UI/notification';
import { messageError, messageSuccess } from 'views/components/UI/message';
import { ITranslate } from 'interfaces/general.interface';
import { IContactItem } from 'interfaces/mainContacts.interface';

export async function getMainContactsAction(
  dispatch: React.Dispatch<any>,
  tenantId: string,
  token: string,
  translate: ITranslate
) {
  try {
    const contactsFetch = await api.getMainContactsAPI(tenantId, token);
    if (contactsFetch.response.status !== 200) return;
    let orderedContacts: IContactItem[] = [];
    contactsFetch.data.company_client_contacts.forEach((item) => {
      const obj = {
        key: item.contact.id,
        name: item.contact.name,
        lastname: item.contact.surname,
        email: item.contact.email,
        phoneNumberPrefix: item.contact.telephone_prefix,
        phoneNumberSufix: item.contact.telephone_sufix,
        sector: item.contact.sector,
      };
      orderedContacts.push(obj);
    });
    return dispatch({
      type: MainContactsTypes.GET_MAIN_CONTACTS,
      payload: orderedContacts,
    });
  } catch (err) {
    messageError(translate('action_get-main-contacts_error'));
    notificationError({
      msg: translate('notification-error_message'),
      description: translate('notification-error_description'),
      errorCode: mainContactError.get_contacts_001,
    });
  }
}

export async function selectContactAction(
  dispatch: React.Dispatch<any>,
  contact: IContactItem,
  translate: ITranslate
) {
  try {
    return dispatch({
      type: MainContactsTypes.SELECT_CONTACT,
      payload: contact,
    });
  } catch (err) {
    messageError(translate('action_select-main-contacts_error'));
    notificationError({
      msg: translate('notification-error_message'),
      description: translate('notification-error_description'),
      errorCode: mainContactError.select_contact_001,
    });
  }
}

export async function createContactAction(
  dispatch: React.Dispatch<any>,
  clientId: string,
  contact: any,
  token: string,
  setIsOpenDrawer: (isOpen: boolean) => void,
  translate: ITranslate
) {
  try {
    const body = {
      email: contact.email,
      name: contact.name,
      surname: contact.surname,
      tel_prefix: contact.telephone_prefix,
      tel_sufix: contact.telephone_sufix,
      sector: contact.sector,
    };
    const createFetch = await api.createMainContactAPI(clientId, body, token);
    const newContact = {
      key: createFetch.id,
      name: createFetch.name,
      lastname: createFetch.surname,
      email: createFetch.email,
      phoneNumberPrefix: createFetch.tel_prefix,
      phoneNumberSufix: createFetch.tel_sufix,
      sector: createFetch.sector,
    };
    dispatch({
      type: MainContactsTypes.CREATE_CONTACT,
      payload: newContact,
    });
    messageSuccess(translate('action_create-main-contact_success'));
    return setIsOpenDrawer(false);
  } catch (err) {
    messageError(translate('action_create-main-contact_error'));
    notificationError({
      msg: translate('notification-error_message'),
      description: translate('notification-error_description'),
      errorCode: mainContactError.create_contact_001,
    });
  }
}

export async function updateContactAction(
  dispatch: React.Dispatch<any>,
  contacts: any,
  newContact: any,
  token: string,
  setIsOpenDrawer: (isOpen: boolean) => void,
  translate: ITranslate
) {
  try {
    const contactId = contacts.contactSelected.key;
    const body = {
      filter: { id: contactId },
      update: {
        email: newContact.email,
        name: newContact.name,
        surname: newContact.surname,
        tel_prefix: newContact.telephone_prefix,
        tel_sufix: newContact.telephone_sufix,
        sector: newContact.sector,
      },
    };
    const updateFetch = await api.updateMainContactAPI(body, token);
    if (updateFetch[0] === 1) {
      const findContact = contacts.contacts.findIndex(
        (item: any) => item.key === contactId
      );
      const newContacts = [...contacts.contacts];
      newContacts[findContact].name = newContact.name;
      newContacts[findContact].lastname = newContact.surname;
      newContacts[findContact].email = newContact.email;
      newContacts[findContact].phoneNumberPrefix = newContact.telephone_prefix;
      newContacts[findContact].phoneNumberSufix = newContact.telephone_sufix;
      newContacts[findContact].sector = newContact.sector;
      dispatch({
        type: MainContactsTypes.UPDATE_CONTACT,
        payload: newContacts,
      });
      messageSuccess(translate('action_update-main-contact_success'));
      return setIsOpenDrawer(false);
    } else {
      messageError(translate('action_update-main-contact_error'));
      notificationError({
        msg: translate('notification-error_message'),
        description: translate('notification-error_description'),
        errorCode: mainContactError.update_contact_001,
      });
    }
  } catch (err) {
    messageError(translate('action_update-main-contact_error'));
    notificationError({
      msg: translate('notification-error_message'),
      description: translate('notification-error_description'),
      errorCode: mainContactError.update_contact_002,
    });
  }
}

export async function removeContactAction(
  dispatch: React.Dispatch<any>,
  contactId: string,
  contacts: IContactItem[],
  token: string,
  translate: ITranslate
) {
  try {
    const removeFetch = await api.removeMainContactAPI(contactId, token);
    if (removeFetch.contact === 1) {
      const newContacts = [...contacts];
      const filteredContacts = newContacts.filter((item: any) => item.key !== contactId);
      dispatch({
        type: MainContactsTypes.REMOVE_CONTACT,
        payload: filteredContacts,
      });
      return messageSuccess(translate('action_delete-main-contact_success'));
    } else {
      messageError(translate('action_delete-main-contact_error'));
      notificationError({
        msg: translate('notification-error_message'),
        description: translate('notification-error_description'),
        errorCode: mainContactError.remove_contact_001,
      });
    }
  } catch (err) {
    messageError(translate('action_delete-main-contact_error'));
    notificationError({
      msg: translate('notification-error_message'),
      description: translate('notification-error_description'),
      errorCode: mainContactError.remove_contact_002,
    });
  }
}
