import {
  ICountryAPIResponseItem,
  IGetExchangesAPIResponseItem,
} from 'api/endpoints/general/general.response.interface';
import { TFunction } from 'i18next';

export interface InitialGeneralState {
  exchanges: Array<IGetExchangesAPIResponseItem>;
  countries: Array<ICountryAPIResponseItem>;
  associationStates: IAssociationsStates | null;
}

export const pending = 'pending';
export const connected = 'connected';
export const rejected = 'rejected';
export const disconnected = 'disconnected';
export const matching = 'matching';

export type TAssociation = {
  id: string;
  state: string;
};

export interface IAssociationsStates {
  pending: TAssociation;
  connected: TAssociation;
  rejected: TAssociation;
  disconnected: TAssociation;
}

export interface ChildrenProps {
  children: React.ReactNode;
}

export type TResponseApi = {
  response: Response;
};

export type ITranslate = TFunction<'translation', undefined>;

export type TAlertTypes = 'warning' | 'success' | 'info' | 'error';
