import {
  IaddSocialMediaAPIResponse,
  IgetCompanyInfoAPIResponse,
  IremoveSocialMediaAPIResponse,
  IupdateCompanyDataAPIBody,
  IupdateCompanyDataAPIResponse,
  IupdateSocialMediaAPIResponse,
  TSocialMedia,
} from 'api/endpoints/company/company.response.interface';
import { getBasePath } from '../../utils/config';

export const getCompanyInfoAPI = async ({
  clientId,
  token,
}: {
  clientId: string;
  token: string;
}): Promise<IgetCompanyInfoAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/client_profile/get_client_profile?client_id=${clientId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const updateCompanyDataAPI = async ({
  body,
  token,
}: {
  body: IupdateCompanyDataAPIBody;
  token: string;
}): Promise<IupdateCompanyDataAPIResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${getBasePath()}/client_profile/update_client_profile`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const addSocialMediaAPI = async ({
  companyId,
  items,
  token,
}: {
  companyId: string;
  items: TSocialMedia[];
  token: string;
}): Promise<IaddSocialMediaAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: companyId,
      items: items,
    }),
  };
  const response = await fetch(`${getBasePath()}/social_media/add_social_media`, params);
  const data = await response.json();
  return { response, data };
};

export const updateSocialMediaAPI = async ({
  socialMedia,
  token,
}: {
  socialMedia: TSocialMedia;
  token: string;
}): Promise<IupdateSocialMediaAPIResponse> => {
  const { client_id, id, url, name } = socialMedia;
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ client_id, id, url, name }),
  };
  const response = await fetch(
    `${getBasePath()}/social_media/update_social_media`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const removeSocialMediaAPI = async ({
  socialMediaId,
  token,
}: {
  socialMediaId: string;
  token: string;
}): Promise<IremoveSocialMediaAPIResponse> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      target: 'client',
      ids: [socialMediaId],
    }),
  };
  const response = await fetch(
    `${getBasePath()}/social_media/delete_social_media`,
    params
  );
  const data = await response.json();
  return { response, data };
};
