import { IPointOfSaleState } from 'api/endpoints/pointsOfSale/pointsOfSale.response.interface';
import { IPointsOfSaleStateInitialState } from 'interfaces/pointsOfSale.interface';

const initialPointsOfSaleState: IPointsOfSaleStateInitialState = {
  pointsOfSale: [],
  pointsOfSaleSelected: {} as IPointOfSaleState,
  pointsOfSaleCategories: [],
  isLoadingGetPointsOfSale: false,
  selectPointOfSale: () => {},
  createPointOfSale: () => {},
  updatePointOfSale: () => {},
  removePointOfSale: () => Promise.resolve(false),
  updatePointOfSaleConnections: () => {},
};

export default initialPointsOfSaleState;
