/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useEffect, useCallback, memo } from 'react';
import UserContext from './UserContext';
import initialUserState from './initialUserState';
import userReducer from 'contexts/user/reducer/user.reducer';
import * as action from 'contexts/user/reducer/user.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import {
  ISAdminClientState,
  ISAdminUserClientState,
} from 'interfaces/superAdmin.interface';
import {
  ICompanyUpdateFields,
  TUpdateLanguageProps,
  TUpdateUserPick,
} from 'interfaces/user.interface';
import { ChildrenProps } from 'interfaces/general.interface';
import useContextUILanguage from 'hooks/contexts/UI/useContextUILanguage';
import { TSocialMedia } from 'api/endpoints/company/company.response.interface';

function UserProvider(props: ChildrenProps) {
  const [userState, dispatch] = useReducer(userReducer, initialUserState);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
  const { t: translate } = useTranslation();
  const { languages } = useContextUILanguage();

  useEffect(() => {
    if (isLoading && !isAuthenticated) return;
    (async () => {
      if (!user?.email_verified) return;
      const token = await getAccessTokenSilently();
      if (!token) return;
      return await action.loginUserAction({
        dispatch,
        token,
        emailVerified: user.email_verified,
        translate,
        logout,
      });
    })();
  }, [user?.email_verified, isLoading, isAuthenticated]);

  const login = useCallback(async () => {
    if (!user?.email_verified) return false;
    const token = await getAccessTokenSilently();
    if (!token) return false;
    return await action.loginUserAction({
      dispatch,
      token,
      emailVerified: user.email_verified,
      translate,
      logout,
    });
  }, [user?.email_verified, translate, userState.dbUser]);

  const updateUser = useCallback(
    async ({ user }: { user: TUpdateUserPick }) => {
      if (!userState.dbUser?.id) return false;
      const token = await getAccessTokenSilently();
      return await action.updateUserAction({
        dispatch,
        dbUserId: userState.dbUser.id,
        user,
        token,
        translate,
      });
    },
    [translate, userState.dbUser?.id]
  );

  const updateCompanyData = useCallback(
    async ({ companyData }: { companyData: ICompanyUpdateFields }) => {
      if (!companyData || !userState.company?.id) return false;
      const token = await getAccessTokenSilently();
      return await action.updateCompanyAction({
        dispatch,
        companyId: userState.company.id,
        companyData,
        token,
        translate,
      });
    },
    [userState.company?.id, translate]
  );

  const updateUserAvatar = useCallback(
    async ({ image }: { image: File }) => {
      if (!userState.dbUser?.id) return false;
      const token = await getAccessTokenSilently();
      return await action.updateUserAvatarAction({
        dispatch,
        userId: userState.dbUser.id,
        image,
        token,
        translate,
      });
    },
    [userState.dbUser?.id, translate]
  );

  const updateCompanyLogo = useCallback(
    async ({ image }: { image: File }) => {
      if (!userState.company?.id) return false;
      const token = await getAccessTokenSilently();
      if (!token) return false;
      return await action.updateCompanyLogoAction({
        dispatch,
        clientId: userState.company.id,
        image,
        token,
        translate,
      });
    },
    [userState.company?.id, translate]
  );

  const updateLanguage = useCallback(
    async ({ language }: { language: TUpdateLanguageProps }) => {
      if (!userState.dbUser || language.id === userState.dbUser.language.id) return false;
      const token = await getAccessTokenSilently();
      if (!token) return false;
      return await action.updateLanguageAction({
        dispatch,
        dbUserId: userState.dbUser.id,
        language,
        token,
        translate,
      });
    },
    [translate, userState.dbUser?.id, userState.dbUser?.language.id]
  );

  const updateSocialMedia = useCallback(
    async ({ socialMedia }: { socialMedia: TSocialMedia }) => {
      if (!userState.company?.social_media) return false;
      const token = await getAccessTokenSilently();
      if (!token) return false;
      return await action.updateSocialMediaAction({
        dispatch,
        socialMediaState: userState.company?.social_media,
        socialMedia,
        token,
        translate,
      });
    },
    [translate, userState.company?.social_media]
  );

  const removeSocialMedia = useCallback(
    async ({ socialMediaId }: { socialMediaId: string }) => {
      if (!userState.company?.social_media) return false;
      const token = await getAccessTokenSilently();
      if (!token) return false;
      return await action.removeSocialMediaAction({
        dispatch,
        socialMediaState: userState.company?.social_media,
        socialMediaId,
        token,
        translate,
      });
    },
    [translate, userState.company?.social_media]
  );

  const selectCompanyAndUserSuperAdmin = useCallback(
    async ({
      client,
      user,
    }: {
      client: ISAdminClientState;
      user: ISAdminUserClientState;
    }) => {
      if (!client || !user || languages.length === 0) return false;
      const token = await getAccessTokenSilently();
      if (!token) return false;
      return await action.selectCompanyAndUserSuperAdminAction({
        dispatch,
        client,
        user,
        languages,
        translate,
        token,
      });
    },
    [languages, translate]
  );

  const selectCompanySuperAdmin = useCallback(
    async ({ client }: { client: ISAdminClientState }) => {
      if (!client) return false;
      const token = await getAccessTokenSilently();
      if (!token) return false;
      return await action.selectCompanySuperAdminAction({
        dispatch,
        client,
        translate,
        token,
      });
    },
    [translate]
  );

  const memoProvider = useMemo(
    () => ({
      ...userState,
      login,
      updateUser,
      updateCompanyData,
      updateUserAvatar,
      updateCompanyLogo,
      updateLanguage,
      updateSocialMedia,
      removeSocialMedia,
      selectCompanyAndUserSuperAdmin,
      selectCompanySuperAdmin,
    }),
    [
      userState,
      login,
      updateUser,
      updateCompanyData,
      updateUserAvatar,
      updateCompanyLogo,
      updateLanguage,
      updateSocialMedia,
      removeSocialMedia,
      selectCompanyAndUserSuperAdmin,
      selectCompanySuperAdmin,
    ]
  );

  return (
    <UserContext.Provider value={memoProvider}>{props.children}</UserContext.Provider>
  );
}

export default memo(UserProvider);
