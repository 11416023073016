import {
  IContactItem,
  IinitialMainContactsState,
} from 'interfaces/mainContacts.interface';

const initialMainContactsState: IinitialMainContactsState = {
  contacts: [] as IContactItem[],
  contactSelected: {} as IContactItem,
  getMainContacts: () => {},
  selectContact: () => {},
  createContact: () => {},
  removeContact: () => {},
  updateContact: () => {},
};

export default initialMainContactsState;
