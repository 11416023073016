import { IColumnsSelectOrderState } from 'views/pages/main/catalog/catalogDownloadPage/components/columnsSelectOrder/ColumnsSelectOrder';
import { MutableRefObject } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import {
  IFavorite,
  TFiltersCatalog,
} from 'api/endpoints/catalog/catalog.response.interface';
import { IcatalogDownloadItemResponse } from 'api/endpoints/catalogDownload/catalogDownload.response.interface';
import { TAlertTypes } from './general.interface';

export interface IInitialCatalogDownloadState {
  // Filters
  catalogBodyFetch: IBodyProductDownloadSearch;
  filters: TFiltersCatalog[];
  imageSelection: TImageSelectionTypes;
  setImageSelection: (value: TImageSelectionTypes) => void;
  setFavorites: (value: boolean) => void;
  setNotDownloaded: (value: boolean) => void;
  setPviSlider: (value: [number, number]) => void;
  setPvprSlider: (value: [number, number]) => void;
  isLoadingFilters: boolean;
  filtersAlert: {
    show: boolean;
    type: TAlertTypes;
    message: string;
    description: string;
  };
  filtersSelected: TCatalogDownloadFiltersSelected;
  isLoadingCatalog: boolean;
  filteredList: IFilteredList;
  setFilteredList: (value: ICatalogFetchCSVDataPlainSkuResponse[]) => void;
  handlePagination: ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => Promise<boolean>;
  selectedValues: React.Key[];
  setSelectedValues: (value: React.Key[]) => void;
  filtersSelectedTags: string[];
  isOpenFilters: boolean;
  filtersSelectedTemp: TCatalogDownloadFiltersSelected;
  openFilters: (value: boolean) => void;
  cancelFilters: () => void;
  cleanFilters: () => void;
  applyFilterChanges: (value: TCatalogDownloadFiltersSelected) => void;
  onChangeFilterSelect: (filterName: string, value: TFiltersSelectsValues[]) => void;
  // Seacth
  querySearch: string;
  setQuerySearch: (value: string) => void;
  applySearch: () => void;
  // Columns order
  columnsOrder: Record<string, IColumnsSelectOrderState>;
  moveColumnsOrder: (result: DropResult) => void;
  checkColumn: (columnKey: string) => void;
  // Download
  downloadingStatus: {
    isDownloading: boolean;
    totalImagesToDownload: number;
    completedDownloads: number;
  };
  downloadCatalog: ({
    completedDownloads,
  }: {
    completedDownloads: MutableRefObject<number>;
  }) => void;
  cancelDownloadCatalog: ({
    completedDownloads,
  }: {
    completedDownloads: MutableRefObject<number>;
  }) => void;
  isDownloadingZip: boolean;
  downloadImages: boolean;
  setDownloadImages: (
    value: boolean,
    columns: Record<string, IColumnsSelectOrderState>
  ) => void;
  xhrsRef: any[];
}

export interface IFilteredList {
  total: number;
  of: number;
  count: number;
  data: ICatalogFetchCSVDataPlainSkuResponse[];
}

export interface IBodyProductDownloadSearch {
  options: TBodyOptions;
  filter: TBodyFilter;
}

type TBodyOptions = {
  brand_id: string[];
  client_id: string;
  not_downloaded?: boolean;
  favorite: string | null;
  with_images: boolean | null;
  search: string[];
  order: string[];
  limit: number;
  index: number;
};

type TBodyFilter = {
  reference: string[];
  color: string[];
  season: string[];
  segmentation: string[];
  division: string[];
  family: string[];
  gender: string[];
  pvi: any[];
  pvpr: any[];
  tag: any[];
  tier: any[];
  bill: string[];
  order: string[];
};

export const imageSelectionTypes = {
  all: 'all',
  withImages: 'with-images',
  withoutImages: 'without-images',
};

export type TImageSelectionTypes = 'all' | 'with-images' | 'without-images';

export type TCatalogDownloadFiltersSelected = {
  brand: TFiltersSelectsValues[];
  division: TFiltersSelectsValues[];
  season: TFiltersSelectsValues[];
  gender: TFiltersSelectsValues[];
  family: TFiltersSelectsValues[];
  color: TFiltersSelectsValues[];
  bill: TFiltersSelectsValues[];
  order: TFiltersSelectsValues[];
};

export type TFiltersSelectsValues = {
  label: string;
  value: string;
  values: string[];
};

export interface ICatalogFetchCSVDataResponse {
  id: string;
  brand: string;
  brand_id: string;
  ranking: number;
  units: number | null;
  favorites: IFavorite[];
  name: string;
  pvi: number;
  pvpr: number;
  reference: string;
  color: string;
  color_code: string;
  images: string[];
  season: string;
  gender: string;
  division: string;
  segmentation: string;
  family: string;
  material: string;
  sku: TEanSizeCSVMode[];
}

type TEanSizeCSVMode = {
  pvpr: number;
  pvi: number;
  ean: string;
  size: string;
};

export interface ICatalogFetchCSVDataPlainSkuResponse
  extends IcatalogDownloadItemResponse {
  key: number;
}
