import {
  ISAdminClientState,
  ISAdminUserClientState,
} from 'interfaces/superAdmin.interface';
import * as UserTypes from './user.types';
import {
  ICompanyState,
  IDbUserState,
  IInitialUserState,
} from 'interfaces/user.interface';
import moment from 'moment';
import { TLanguageResponseItem } from 'api/endpoints/general/general.response.interface';
import { TSocialMedia } from 'api/endpoints/company/company.response.interface';

export default function userReducer(state: IInitialUserState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case UserTypes.LOGIN_USER: {
      const loginUser: IInitialUserState = {
        ...state,
        dbUser: {
          ...state.dbUser,
          id: payload?.user?.id,
          auth0Id: payload.user?.auth_id,
          email: payload?.user?.email,
          name: payload?.user?.name,
          surname: payload?.user?.surname,
          telephone_prefix: payload?.user?.telephone_prefix,
          telephone_sufix: payload?.user?.telephone_sufix,
          profile_image: payload?.user?.profile_image
            ? `${payload?.user?.profile_image}?ts=${moment().unix()}`
            : '',
          language: {
            ...state.dbUser?.language,
            id: payload?.user?.i18n_lang?.id ?? 'd54451a1-674a-4f97-a769-acff8ab795bb',
            code: payload?.user?.i18n_lang?.code ?? 'es',
          },
          role: payload?.user?.role,
          sector: payload?.user?.sector,
          signed: payload?.user?.signed,
          first_login: payload?.user?.first_login,
          enabled: payload?.user?.enabled,
          user_tenants: payload?.user?.user_clients,
        },
        company: {
          ...(state.company as ICompanyState),
          id: payload?.company?.id,
          cif: payload?.company?.cif,
          name: payload?.company?.name,
          legal_name: payload?.company?.legal_name,
          address: payload?.company?.address,
          postal_code: payload?.company?.postal_code,
          city: payload?.company?.city,
          province: payload?.company?.province,
          country: {
            id: payload?.company?.country?.id,
            name: payload?.company?.country?.name,
          },
          floor: payload?.company?.floor,
          door: payload?.company?.door,
          lat: payload?.company?.lat,
          lng: payload?.company?.lng,
          contact_name: payload?.company?.contact_name,
          email: payload?.company?.email,
          telephone_prefix: payload?.company?.telephone_prefix,
          telephone_sufix: payload?.company?.telephone_sufix,
          logo: payload?.company?.logo
            ? `${payload?.company?.logo}?ts=${moment().unix()}`
            : '',
          dashboard: payload?.user?.user_clients
            ? payload?.user?.user_clients[0]?.client?.dashboard
            : '',
          web: payload?.company?.web,
          instagram: payload?.company?.instagram,
          youtube: payload?.company?.youtube,
          facebook: payload?.company?.facebook,
          linkedin: payload?.company?.linkedin,
          social_media: payload?.company?.client_social_media?.map(
            (social: TSocialMedia) => {
              return {
                id: social.id,
                name: social.name,
                url: social.url,
                client_id: social.client_id,
              };
            }
          ),
        },
      };
      return loginUser;
    }

    case UserTypes.LOGIN_COMPANY_USER_SUPER_ADMIN: {
      const user = payload.user as ISAdminUserClientState;
      const company = payload.company as ISAdminClientState;
      const loginCompanyUserSuperAdmin: IInitialUserState = {
        ...state,
        dbUser: {
          ...(state.dbUser as IDbUserState),
          id: user.user_id,
          auth0Id: user.user.auth_id,
          email: user.user.username,
          name: user.user.name,
          surname: user.user.surname,
          telephone_prefix: user.user.tel_prefix,
          telephone_sufix: user.user.tel_sufix,
          profile_image: user.user.profile_image
            ? `${user.user.profile_image}?ts=${moment().unix()}`
            : '',
          language: {
            ...state.dbUser?.language,
            id: user.user.i18n_lang_id ?? 'd54451a1-674a-4f97-a769-acff8ab795bb',
            code:
              payload.languages.find(
                (lang: TLanguageResponseItem) => lang.id === user.user.i18n_lang_id
              ).code ?? 'es',
          },
          sector: user.user.sector,
          signed: user.user.signed,
          first_login: user.user.first_login,
          enabled: user.user.enabled,
          user_tenants: company.user_clients,
        },
        company: {
          ...(state.company as ICompanyState),
          id: company.id,
          cif: company.cif,
          name: company.name,
          legal_name: company.legal_name,
          address: company.address,
          postal_code: company.postal_code,
          city: company.city,
          province: company.province,
          country: {
            id: company.country_id,
            name: company.country.name,
          },
          floor: company.floor,
          door: company.door,
          lat: company.lat,
          lng: company.lng,
          contact_name: company.contact_name,
          email: company.email,
          telephone_prefix: company.tel_prefix,
          telephone_sufix: company.tel_sufix,
          logo: company.logo ? `${company.logo}?ts=${moment().unix()}` : '',
          dashboard: company.dashboard,
          web: company.web,
          instagram: company.instagram,
          youtube: company.youtube,
          facebook: company.facebook,
          linkedin: company.linkedin,
          social_media: company?.social_media.map((social) => {
            return {
              id: social.id,
              name: social.name,
              url: social.url,
              client_id: social.client_id,
            };
          }),
        },
      };
      return loginCompanyUserSuperAdmin;
    }

    case UserTypes.LOGIN_COMPANY_SUPER_ADMIN: {
      const loginCompanySuperAdmin: IInitialUserState = {
        ...state,
        company: {
          ...(state.company as ICompanyState),
          id: payload.company.id,
          cif: payload.company.cif,
          name: payload.company.name,
          legal_name: payload.company.legal_name,
          address: payload.company.address,
          postal_code: payload.company.postal_code,
          city: payload.company.city,
          province: payload.company.province,
          country: {
            id: payload.company.country_id,
            name: payload.company.country.name,
          },
          floor: payload.company.floor,
          door: payload.company.door,
          lat: payload.company.lat,
          lng: payload.company.lng,
          contact_name: payload.company.contact_name,
          email: payload.company.email,
          telephone_prefix: payload.company.tel_prefix,
          telephone_sufix: payload.company.tel_sufix,
          logo: payload.company.logo
            ? `${payload.company.logo}?ts=${moment().unix()}`
            : '',
          dashboard: payload.company.dashboard,
          web: payload.company.web,
          instagram: payload.company.instagram,
          youtube: payload.company.youtube,
          facebook: payload.company.facebook,
          linkedin: payload.company.linkedin,
          social_media: payload.company?.social_media.map((social: TSocialMedia) => {
            return {
              id: social.id,
              name: social.name,
              url: social.url,
              client_id: social.client_id,
            };
          }),
        },
      };
      return loginCompanySuperAdmin;
    }

    case UserTypes.UPDATE_USER: {
      const updateUser: IInitialUserState = {
        ...state,
        dbUser: {
          ...(state.dbUser as IDbUserState),
          name: payload.name,
          surname: payload.surname,
          telephone_prefix: payload.telephone_prefix,
          telephone_sufix: payload.telephone_sufix,
          sector: payload.sector,
        },
      };
      return updateUser;
    }

    case UserTypes.UPDATE_COMPANY: {
      const {
        name,
        legal_name,
        country_id,
        country_name,
        province,
        city,
        address,
        postal_code,
        email,
        tel_prefix,
        tel_sufix,
        cif,
        floor,
        door,
        lat,
        lng,
        contact_name,
        web,
        instagram,
        youtube,
        linkedin,
        facebook,
      } = payload;
      const updateCompany: IInitialUserState = {
        ...state,
        company: {
          ...(state.company as ICompanyState),
          name,
          legal_name,
          country: {
            id: country_id,
            name: country_name,
          },
          province,
          city,
          address,
          postal_code,
          email,
          telephone_prefix: tel_prefix,
          telephone_sufix: tel_sufix,
          cif,
          floor,
          door,
          lat,
          lng,
          contact_name,
          web,
          instagram,
          youtube,
          linkedin,
          facebook,
        },
      };
      return updateCompany;
    }

    case UserTypes.UPDATE_USER_AVATAR: {
      const updateUserAvatar: IInitialUserState = {
        ...state,
        dbUser: {
          ...(state.dbUser as IDbUserState),
          profile_image: `${payload.url}?ts=${moment().unix()}`,
        },
      };
      return updateUserAvatar;
    }

    case UserTypes.UPDATE_COMPANY_LOGO: {
      const updateCompanyLogo: IInitialUserState = {
        ...state,
        company: {
          ...(state.company as ICompanyState),
          logo: `${payload.url}?ts=${moment().unix()}`,
        },
      };
      return updateCompanyLogo;
    }

    case UserTypes.VERIFY_EMAIL: {
      const verifyEmail: IInitialUserState = {
        ...state,
        dbUser: {
          ...(state.dbUser as IDbUserState),
          enabled: payload,
        },
      };
      return verifyEmail;
    }

    case UserTypes.UPDATE_LANGUAGE: {
      const updateLanguage: IInitialUserState = {
        ...state,
        dbUser: {
          ...(state.dbUser as IDbUserState),
          language: payload,
        },
      };
      return updateLanguage;
    }

    case UserTypes.ADD_SOCIAL_MEDIA: {
      const addSocialMedia: IInitialUserState = {
        ...state,
        company: {
          ...(state.company as ICompanyState),
          social_media: [...(state.company as ICompanyState).social_media, payload],
        },
      };
      return addSocialMedia;
    }

    case UserTypes.SET_SOCIAL_MEDIA: {
      const setSocialMedia: IInitialUserState = {
        ...state,
        company: {
          ...(state.company as ICompanyState),
          social_media: payload,
        },
      };
      return setSocialMedia;
    }

    case UserTypes.SET_IS_LOADING: {
      const setIsLoading: IInitialUserState = {
        ...state,
        isLoading: payload,
      };
      return setIsLoading;
    }

    default:
      return state;
  }
}
