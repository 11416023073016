import {
  IgetCatalogDownloadAPIResponse,
  IregisterProductDownloadAPIResponse,
} from 'api/endpoints/catalogDownload/catalogDownload.response.interface';
import { getBasePath } from '../../utils/config';
import { IBodyProductDownloadSearch } from 'interfaces/catalogDownload.interface';

export const getCatalogDownloadAPI = async ({
  body,
  token,
  signal,
}: {
  body: IBodyProductDownloadSearch;
  token: string;
  signal: AbortSignal | undefined;
}): Promise<IgetCatalogDownloadAPIResponse> => {
  const params = {
    signal: signal,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/product/brand/download_product`, params);
  const data = await response.json();
  return { response, data };
};

export const registerProductDownloadAPI = async ({
  clientId,
  sku_ids,
  token,
}: {
  clientId: string;
  sku_ids: string[];
  token: string;
}): Promise<IregisterProductDownloadAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: clientId,
      sku_ids: sku_ids,
    }),
  };
  const response = await fetch(
    `${getBasePath()}/product/brand/register_product_download`,
    params
  );
  const data = await response.json();
  return { response, data };
};
