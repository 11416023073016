import { getBasePath } from '../../utils/config';
import {
  IgetPointOfSaleAPIResponse,
  TGetPointOfSaleCategoriesAPI,
  TremovePointOfSaleAPIResponse,
} from 'api/endpoints/pointsOfSale/pointsOfSale.response.interface';

export const getPointsOfSaleAPI = async (
  clientId: string,
  token: string
): Promise<IgetPointOfSaleAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/client_profile/get_client_points_of_sale?client_id=${clientId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const createPointsOfSaleAPI = async (
  pointOfSale: any,
  token: string
): Promise<any> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(pointOfSale),
  };
  const response = await fetch(
    `${getBasePath()}/point_of_sale_profile/create_point_of_sale`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const updatePointOfSaleAPI = async (body: any, token: string): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${getBasePath()}/point_of_sale_profile/update_point_of_sale`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const removePointOfSaleAPI = async (
  pointOfSaleId: string,
  token: string
): Promise<TremovePointOfSaleAPIResponse> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/point_of_sale_profile/delete_point_of_sale?point_of_sale_id=${pointOfSaleId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getPointsOfSaleCategoriesAPI = async (
  token: string
): Promise<TGetPointOfSaleCategoriesAPI> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/point_of_sale_profile/get_point_of_sale_category`,
    params
  );
  const data = await response.json();
  return { response, data };
};
