/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo } from 'react';
import MainContactsContext from './MainContactsContext';
import initialMainContactsState from './initialMainContactsState';
import userReducer from 'contexts/mainContacts/reducer/mainContacts.reducer';
import * as action from 'contexts/mainContacts/reducer/mainContacts.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps } from 'interfaces/general.interface';
import { IContactItem } from 'interfaces/mainContacts.interface';

function MainContactsProvider(props: ChildrenProps) {
  const [contacts, dispatch] = useReducer(userReducer, initialMainContactsState);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { t: translate } = useTranslation();
  const { company } = useContextUser();

  const getMainContacts = useCallback(async () => {
    if (!company?.id || !isAuthenticated) return false;
    const token = await getAccessTokenSilently();
    if (!token) return false;
    return await action.getMainContactsAction(dispatch, company.id, token, translate);
  }, [company?.id, translate, isAuthenticated]);

  const selectContact = useCallback(
    (contact: IContactItem) => {
      action.selectContactAction(dispatch, contact, translate);
    },
    [translate]
  );

  const createContact = useCallback(
    async (contact: IContactItem, setIsOpenDrawer: (isOpen: boolean) => void) => {
      if (!company?.id) return false;
      const token = await getAccessTokenSilently();
      if (!token) return false;
      return await action.createContactAction(
        dispatch,
        company.id,
        contact,
        token,
        setIsOpenDrawer,
        translate
      );
    },
    [company?.id, translate]
  );

  const updateContact = useCallback(
    async (contact: IContactItem, setIsOpenDrawer: (isOpen: boolean) => void) => {
      const token = await getAccessTokenSilently();
      action.updateContactAction(
        dispatch,
        contacts,
        contact,
        token,
        setIsOpenDrawer,
        translate
      );
    },
    [contacts, translate]
  );

  const removeContact = useCallback(
    async (contactId: string) => {
      const token = await getAccessTokenSilently();
      action.removeContactAction(
        dispatch,
        contactId,
        contacts.contacts,
        token,
        translate
      );
    },
    [contacts, translate]
  );

  const memoProvider = useMemo(
    () => ({
      ...contacts,
      getMainContacts,
      selectContact,
      createContact,
      updateContact,
      removeContact,
    }),
    [
      contacts,
      getMainContacts,
      selectContact,
      createContact,
      updateContact,
      removeContact,
    ]
  );

  return (
    <MainContactsContext.Provider value={memoProvider}>
      {props.children}
    </MainContactsContext.Provider>
  );
}

export default memo(MainContactsProvider);
