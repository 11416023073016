import * as types from './catalogDownload.types';
import {
  IBodyProductDownloadSearch,
  ICatalogFetchCSVDataPlainSkuResponse,
  IFilteredList,
  IInitialCatalogDownloadState,
  TCatalogDownloadFiltersSelected,
  imageSelectionTypes,
} from 'interfaces/catalogDownload.interface';
import { catalogDownloadFiltersKeys } from '../CatalogDownloadProvider';
import { abortCatalogController } from 'utils/abortController';
import { getFiltersAPI } from 'api/endpoints/catalog/catalog.api';
import { IMyBrand } from 'interfaces/myBrands.interface';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import pLimit from 'p-limit';
import { messageInfo, messageSuccess } from 'views/components/UI/message';
import { Key, MutableRefObject } from 'react';
import { IColumnsSelectOrderState } from 'views/pages/main/catalog/catalogDownloadPage/components/columnsSelectOrder/ColumnsSelectOrder';
import { DropResult } from 'react-beautiful-dnd';
import {
  getCatalogDownloadAPI,
  registerProductDownloadAPI,
} from 'api/endpoints/catalogDownload/catalogDownload.api';
import { IcatalogDownloadItemResponse } from 'api/endpoints/catalogDownload/catalogDownload.response.interface';
import { ITranslate } from 'interfaces/general.interface';

type TapplyFilterChangesAction = {
  dispatch: React.Dispatch<any>;
  value: TCatalogDownloadFiltersSelected;
  catalogDownloadState: IInitialCatalogDownloadState;
  dbUserLanguageId: string;
  clientId: string;
  translate: ITranslate;
  token: string;
};
export async function applyFilterChangesAction({
  dispatch,
  value,
  catalogDownloadState,
  dbUserLanguageId,
  clientId,
  translate,
  token,
}: TapplyFilterChangesAction) {
  dispatch({ type: types.SET_SELECTED_VALUES, payload: [] });
  catalogDownloadState.catalogBodyFetch.options.search = [];
  dispatch({ type: types.SET_QUERY_SEARCH, payload: '' });
  if (
    !catalogDownloadState.filtersSelectedTemp.brand ||
    catalogDownloadState.filtersSelectedTemp.brand.length === 0
  ) {
    dispatch({
      type: types.SET_FILTERS_ALERT,
      payload: {
        description: '',
        message: translate('catalog_download_alert_brand-required'),
        show: true,
        type: 'warning',
      },
    });
    setTimeout(() => {
      dispatch({
        type: types.SET_FILTERS_ALERT,
        payload: {
          description: '',
          message: '',
          show: false,
          type: 'info',
        },
      });
    }, 5000);
    return;
  }

  dispatch({ type: types.SET_FILTERS_SELECTED, payload: value });
  // Generate tags
  if (Object.keys(value).length === 0) return;
  let stringValues: string[] = [];
  const mapInputs = Object.keys(value).map((key) => {
    return { key, value: value[key as keyof typeof value] };
  });
  mapInputs.forEach((input) => {
    input.value.forEach((value) => {
      stringValues.push(value.label);
    });
  });
  const imageSelectionLabel = () => {
    if (catalogDownloadState.imageSelection === imageSelectionTypes.withImages) {
      return translate('catalog_download_columns_with-images');
    } else if (
      catalogDownloadState.imageSelection === imageSelectionTypes.withoutImages
    ) {
      return translate('catalog_download_columns_without-images');
    } else {
      return translate('catalog_download_columns_all-images');
    }
  };
  if (catalogDownloadState.catalogBodyFetch.options.favorite) {
    stringValues.push(translate('catalog_download_columns_favorites'));
  } else {
    stringValues = stringValues.filter(
      (item) => item !== translate('catalog_download_columns_favorites')
    );
  }
  if (catalogDownloadState.catalogBodyFetch.options.not_downloaded) {
    stringValues.push(translate('catalog_download_columns_not-downloaded'));
  } else {
    stringValues = stringValues.filter(
      (item) => item !== translate('catalog_download_columns_not-downloaded')
    );
  }
  if (
    catalogDownloadState.catalogBodyFetch.filter.pvi[0] >= 0 &&
    catalogDownloadState.catalogBodyFetch.filter.pvi[1] > 0
  ) {
    stringValues.push(
      `PVI ${catalogDownloadState.catalogBodyFetch.filter.pvi[0]}€-${catalogDownloadState.catalogBodyFetch.filter.pvi[1]}€`
    );
  } else {
    stringValues = stringValues.filter(
      (item) =>
        item !==
        `PVI ${catalogDownloadState.catalogBodyFetch.filter.pvi[0]}€-${catalogDownloadState.catalogBodyFetch.filter.pvi[1]}€`
    );
  }
  if (
    catalogDownloadState.catalogBodyFetch.filter.pvpr[0] >= 0 &&
    catalogDownloadState.catalogBodyFetch.filter.pvpr[1] > 0
  ) {
    stringValues.push(
      `PVPR ${catalogDownloadState.catalogBodyFetch.filter.pvpr[0]}€-${catalogDownloadState.catalogBodyFetch.filter.pvpr[1]}€`
    );
  } else {
    stringValues = stringValues.filter(
      (item) =>
        item !==
        `PVPR ${catalogDownloadState.catalogBodyFetch.filter.pvpr[0]}€-${catalogDownloadState.catalogBodyFetch.filter.pvpr[1]}€`
    );
  }
  stringValues.push(imageSelectionLabel());
  dispatch({ type: types.SET_FILTERS_TAGS, payload: stringValues ?? [] });

  // Set columns
  const withoutImages =
    catalogDownloadState.imageSelection === imageSelectionTypes.withoutImages;
  dispatch({
    type: types.SET_COLUMNS_ORDER,
    payload: {
      ...catalogDownloadState.columnsOrder,
      images: {
        ...catalogDownloadState.columnsOrder.images,
        show: withoutImages ? false : true,
        required: withoutImages ? false : true,
      },
      color_code: {
        ...catalogDownloadState.columnsOrder.color_code,
        show: catalogDownloadState.columnsOrder.color_code.show,
        required: withoutImages ? false : true,
      },
      reference: {
        ...catalogDownloadState.columnsOrder.reference,
        show: catalogDownloadState.columnsOrder.reference.show,
        required: withoutImages ? false : true,
      },
    },
  });
  dispatch({ type: types.SET_DOWNLOAD_IMAGES, payload: withoutImages ? false : true });

  // Set body
  const inputs = Object.keys(value).map((key) => {
    let values: string[] = [];
    value[key as keyof typeof value].forEach((item) => {
      item.values.forEach((value) => {
        values.push(value);
      });
    });
    return { key, values: values };
  });
  catalogDownloadState.catalogBodyFetch.options.client_id = clientId;
  catalogDownloadState.catalogBodyFetch.options.brand_id =
    inputs.find((item) => item.key === catalogDownloadFiltersKeys.brand)?.values || [];
  // catalogDownloadState.catalogBodyFetch.options.language_id = dbUserLanguageId;
  catalogDownloadState.catalogBodyFetch.filter.color =
    inputs.find((item) => item.key === catalogDownloadFiltersKeys.color)?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.season =
    inputs.find((item) => item.key === catalogDownloadFiltersKeys.season)?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.division =
    inputs.find((item) => item.key === catalogDownloadFiltersKeys.division)?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.family =
    inputs.find((item) => item.key === catalogDownloadFiltersKeys.family)?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.gender =
    inputs.find((item) => item.key === catalogDownloadFiltersKeys.gender)?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.bill =
    inputs.find((item) => item.key === catalogDownloadFiltersKeys.bill)?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.order =
    inputs.find((item) => item.key === catalogDownloadFiltersKeys.order)?.values || [];

  if (catalogDownloadState.imageSelection === 'all') {
    catalogDownloadState.catalogBodyFetch.options.with_images = null;
  } else if (catalogDownloadState.imageSelection === 'with-images') {
    catalogDownloadState.catalogBodyFetch.options.with_images = true;
  } else if (catalogDownloadState.imageSelection === 'without-images') {
    catalogDownloadState.catalogBodyFetch.options.with_images = false;
  }

  await getCatalogData({
    dispatch,
    catalogBodyFetch: catalogDownloadState.catalogBodyFetch,
    token,
  });
}

type TgetFiltersAction = {
  dispatch: React.Dispatch<any>;
  dbUserLanguageId: string;
  companyId: string;
  countryId: string;
  connectedBrands: IMyBrand[];
  token: string;
  translate: ITranslate;
};
export async function getFiltersAction({
  dispatch,
  dbUserLanguageId,
  companyId,
  countryId,
  connectedBrands,
  token,
  translate,
}: TgetFiltersAction) {
  dispatch({ type: types.SET_IS_LOADING_FILTERS, payload: true });
  if (connectedBrands.length === 0 || !companyId || !countryId || !dbUserLanguageId)
    return;
  const brandsMap = connectedBrands.map((brand) => brand.brand.id);
  try {
    if (brandsMap.length > 0 && token) {
      const filtersFetch = await getFiltersAPI(
        brandsMap,
        companyId,
        countryId,
        dbUserLanguageId,
        token
      );
      if (filtersFetch.response.status === 200) {
        dispatch({ type: types.SET_FILTERS, payload: filtersFetch.data });
      } else {
        dispatch({
          type: types.SET_FILTERS_ALERT,
          payload: {
            show: true,
            type: 'error',
            message: translate('catalog_download_alert_get-filters-error'),
            description: '',
          },
        });
      }
    }
  } catch (error) {
    console.log(error);
    dispatch({
      show: true,
      type: 'error',
      message: translate('catalog_download_alert_get-filters-error'),
      description: '',
    });
  } finally {
    dispatch({ type: types.SET_IS_LOADING_FILTERS, payload: false });
  }
}

// COLUMNS ORDER /////////////////////////////////
export async function setColumnsOrderAction({
  dispatch,
  value,
}: {
  dispatch: React.Dispatch<any>;
  value: Record<string, IColumnsSelectOrderState>;
}) {
  dispatch({ type: types.SET_COLUMNS_ORDER, payload: value });
}

export async function moveColumnsOrderAction({
  dispatch,
  result,
  columnsOrder,
}: {
  dispatch: React.Dispatch<any>;
  result: DropResult;
  columnsOrder: Record<string, IColumnsSelectOrderState>;
}) {
  if (!result.destination) return;
  const items = Object.entries(columnsOrder);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);
  const updatedItems = items.map(([key, column], index) => {
    return [key, { ...column, order: index }];
  });
  dispatch({ type: types.SET_COLUMNS_ORDER, payload: Object.fromEntries(updatedItems) });
}

export async function checkColumnAction({
  dispatch,
  columnKey,
  columnsOrder,
}: {
  dispatch: React.Dispatch<any>;
  columnKey: string;
  columnsOrder: Record<string, IColumnsSelectOrderState>;
}) {
  dispatch({
    type: types.SET_COLUMNS_ORDER,
    payload: {
      ...columnsOrder,
      [columnKey]: {
        ...columnsOrder[columnKey],
        show: !columnsOrder[columnKey].show,
      },
    },
  });
}

// SEARCH /////////////////////////////////
export async function applySearchAction({
  dispatch,
  catalogDownloadState,
  translate,
  token,
}: {
  dispatch: React.Dispatch<any>;
  catalogDownloadState: IInitialCatalogDownloadState;
  translate: ITranslate;
  token: string;
}) {
  catalogDownloadState.catalogBodyFetch.options.search =
    catalogDownloadState.querySearch.length === 0
      ? []
      : [`%${catalogDownloadState.querySearch.split(' ')}%`];
  await getCatalogData({
    dispatch,
    catalogBodyFetch: catalogDownloadState.catalogBodyFetch,
    // imageSelection: catalogDownloadState.imageSelection,
    token,
  });
}

// DOWNLOAD CATALOG /////////////////////////////////
interface ICsvObj {
  [key: string]: any;
  images: string | undefined;
}
export async function donwloadCatalogAction({
  dispatch,
  clientId,
  catalogDownloadState,
  completedDownloads,
  translate,
  token,
}: {
  dispatch: React.Dispatch<any>;
  clientId: string;
  catalogDownloadState: IInitialCatalogDownloadState;
  completedDownloads: MutableRefObject<number>;
  translate: ITranslate;
  token: string;
}) {
  dispatch({
    type: types.SET_DOWNLOADING_STATUS,
    payload: {
      isDownloading: true,
    },
  });
  try {
    let mountedCSV: any[] = [];
    let imagesToDownloadSet: Set<string> = new Set();
    let sku_ids_set: Set<string> = new Set();

    // Obtain remaining catalog data
    if (
      catalogDownloadState.selectedValues.length >
      catalogDownloadState.filteredList.data.length
    ) {
      const chunkSize = 1000;
      const chunks: Key[][] = [];
      for (let i = 0; i < catalogDownloadState.selectedValues.length; i += chunkSize) {
        chunks.push(catalogDownloadState.selectedValues.slice(i, i + chunkSize));
      }
      let allProducts: IcatalogDownloadItemResponse[] = [];
      for (const chunk of chunks) {
        const signal = abortCatalogController();
        const response = await getCatalogDownloadAPI({
          body: {
            ...catalogDownloadState.catalogBodyFetch,
            options: {
              ...catalogDownloadState.catalogBodyFetch.options,
              index: chunk[0] as number,
              limit: chunk.length,
            },
          },
          token,
          signal,
        });
        allProducts = [...allProducts, ...response.data.data];
      }
      // Create csv object
      for (const item of allProducts) {
        sku_ids_set.add(item.sku_id);
        item?.images?.forEach((image) => {
          imagesToDownloadSet.add(image.url);
        });
        const csv: ICsvObj = {
          ...item,
          images:
            item?.images.map((image) => image.url.split('/').pop()).join(' | ') || '',
        };
        // Create an array of keys sorted by the order
        const keysSortedByOrder = Object.keys(catalogDownloadState.columnsOrder)
          .filter((key) => catalogDownloadState.columnsOrder[key].show) // Only include keys where 'show' is true
          .sort(
            (a, b) =>
              catalogDownloadState.columnsOrder[a].order -
              catalogDownloadState.columnsOrder[b].order
          );
        // Create a new object with keys in the sorted order
        const sortedObj: { [key: string]: any } = {};
        for (const key of keysSortedByOrder) {
          if (key in csv!) {
            sortedObj[key] = csv![key as keyof ICsvObj];
          }
        }
        mountedCSV.push(sortedObj);
      }
    } else {
      // Create csv object
      for (const value of catalogDownloadState.selectedValues) {
        const findItem = catalogDownloadState.filteredList.data.find(
          (item) => item.key === (value as number)
        );
        findItem?.sku_id && sku_ids_set.add(findItem.sku_id);
        findItem?.images?.forEach((image) => {
          imagesToDownloadSet.add(image.url);
        });
        const csv: ICsvObj = {
          ...findItem,
          images:
            findItem?.images.map((image) => image.url.split('/').pop()).join(' | ') || '',
        };
        // Create an array of keys sorted by the order
        const keysSortedByOrder = Object.keys(catalogDownloadState.columnsOrder)
          .filter((key) => catalogDownloadState.columnsOrder[key].show) // Only include keys where 'show' is true
          .sort(
            (a, b) =>
              catalogDownloadState.columnsOrder[a].order -
              catalogDownloadState.columnsOrder[b].order
          );
        // Create a new object with keys in the sorted order
        const sortedObj: { [key: string]: any } = {};
        for (const key of keysSortedByOrder) {
          if (key in csv!) {
            sortedObj[key] = csv![key as keyof ICsvObj];
          }
        }
        mountedCSV.push(sortedObj);
      }
    }

    // Register the products as downloaded
    let sku_ids: string[] = Array.from(sku_ids_set);
    try {
      await registerProductDownloadAPI({
        clientId,
        sku_ids,
        token,
      });
    } catch (error) {
      console.log(error);
    }

    // Download the CSV and updates the states
    const csv = Papa.unparse(mountedCSV);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `csv_${moment().format('DD-MM-YYYY-HH:mm:ss')}.csv`);
    if (catalogDownloadState.downloadImages) {
      if (imagesToDownloadSet.size > 50 && imagesToDownloadSet.size < 500) {
        messageInfo(translate('catalog_download_csv-msg-pending-images'));
      }
      if (imagesToDownloadSet.size > 500) {
        messageInfo(translate('catalog_download_csv-msg-pending-images-navigate'), 5);
      }
    } else {
      imagesToDownloadSet.size > 50 &&
        messageSuccess(translate('catalog_download_csv-msg-without-images'));
    }

    // Break if the user doesn't want to download the images
    if (!catalogDownloadState.downloadImages) return;
    let imagesToDownload: string[] = Array.from(imagesToDownloadSet);
    dispatch({
      type: types.SET_DOWNLOADING_STATUS,
      payload: {
        totalImagesToDownload: imagesToDownload.length,
      },
    });

    const downloadImage = (url: string, zip: JSZip) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        catalogDownloadState.xhrsRef.push(xhr);
        dispatch({ type: types.SET_XHRS_REF, payload: catalogDownloadState.xhrsRef });
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          if (this.status === 200) {
            const blob = new Blob([this.response], { type: 'image/jpg' });
            zip.file(`${url.split('/').pop()}`, blob, { binary: true });
            completedDownloads.current = completedDownloads.current++;
            dispatch({
              type: types.SET_DOWNLOADING_STATUS,
              payload: {
                completedDownloads: completedDownloads.current++,
              },
            });
            resolve(true);
          } else {
            console.error('Image download failed: ' + this.statusText);
            completedDownloads.current = completedDownloads.current++;
            dispatch({
              type: types.SET_DOWNLOADING_STATUS,
              payload: {
                completedDownloads: completedDownloads.current++,
              },
            });
            resolve(false);
          }
        };
        xhr.onerror = function (err) {
          console.error('There was a network error.', err);
          completedDownloads.current = completedDownloads.current++;
          dispatch({
            type: types.SET_DOWNLOADING_STATUS,
            payload: {
              completedDownloads: completedDownloads.current++,
            },
          });
          resolve(false);
        };
        xhr.send();
      });
    };

    // Divide imagesToDownload in sub -rays of 1000 elements
    const chunkSize = 1000;
    const imageChunks = [];
    for (let i = 0; i < imagesToDownload.length; i += chunkSize) {
      imageChunks.push(imagesToDownload.slice(i, i + chunkSize));
    }

    // For each sub -raray, download the images, buy them in a zip file and download it
    for (let i = 0; i < imageChunks.length; i++) {
      dispatch({ type: types.SET_IS_DOWNLOADING_ZIP, payload: true });
      const zip = new JSZip();
      const limit = pLimit(50); // Limit to 10 concurrent requests
      await Promise.all(
        imageChunks[i].map((url) => limit(() => downloadImage(encodeURI(url), zip)))
      ).then(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(
            content,
            `images_${i + 1}-${imageChunks.length}_${moment().format('DD-MM-YYYY-HH:mm:ss')}.zip`
          );
          if (i === imageChunks.length - 1) {
            // Check if this is the last zip file
            dispatch({ type: types.SET_IS_DOWNLOADING_ZIP, payload: false });
            if (imagesToDownloadSet.size < 50) {
              messageSuccess(translate('catalog_download_success-msg-imgs-and-csv'));
            } else {
              messageSuccess(translate('catalog_download_success-msg'));
            }
          }
        });
      });
    }
    completedDownloads.current = 0;
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({
      type: types.SET_DOWNLOADING_STATUS,
      payload: {
        isDownloading: false,
        totalImagesToDownload: 0,
        completedDownloads: 0,
      },
    });
    dispatch({ type: types.SET_XHRS_REF, payload: [] });
    completedDownloads.current = 0;
  }
}

export async function cancelDownloadCatalogAction({
  dispatch,
  catalogDownloadState,
  completedDownloads,
}: {
  dispatch: React.Dispatch<any>;
  catalogDownloadState: IInitialCatalogDownloadState;
  completedDownloads: MutableRefObject<number>;
}) {
  if (catalogDownloadState.downloadingStatus.isDownloading) {
    for (const xhr of catalogDownloadState.xhrsRef) {
      xhr.abort();
    }
    dispatch({ type: types.SET_XHRS_REF, payload: [] });
    dispatch({
      type: types.SET_DOWNLOADING_STATUS,
      payload: {
        isDownloading: false,
        totalImagesToDownload: 0,
        completedDownloads: 0,
      },
    });
    dispatch({ type: types.SET_IS_DOWNLOADING_ZIP, payload: false });
    completedDownloads.current = 0;
    return;
  } else {
    dispatch({ type: types.SET_SELECTED_VALUES, payload: [] });
  }
}

export async function setDownloadImagesAction({
  dispatch,
  value,
  columns,
}: {
  dispatch: React.Dispatch<any>;
  value: boolean;
  columns: Record<string, IColumnsSelectOrderState>;
}) {
  dispatch({ type: types.SET_DOWNLOAD_IMAGES, payload: value });
  dispatch({
    type: types.SET_COLUMNS_ORDER,
    payload: {
      ...columns,
      images: {
        ...columns.images,
        show: value === true ? true : columns.images.show,
        required: value,
      },
      color_code: {
        ...columns.color_code,
        show: value === true ? true : columns.color_code.show,
        required: value,
      },
      reference: {
        ...columns.reference,
        show: value === true ? true : columns.reference.show,
        required: value,
      },
    },
  });
}

export async function handlePaginationAction({
  dispatch,
  page,
  pageSize,
  catalogDownloadState,
  token,
}: {
  dispatch: React.Dispatch<any>;
  page: number;
  pageSize: number;
  catalogDownloadState: IInitialCatalogDownloadState;
  token: string;
}) {
  catalogDownloadState.catalogBodyFetch.options.index =
    page === 1 ? 0 : page * pageSize - pageSize;
  catalogDownloadState.catalogBodyFetch.options.limit = pageSize;
  await getCatalogData({
    dispatch,
    catalogBodyFetch: catalogDownloadState.catalogBodyFetch,
    token,
  });
  return true;
}

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
export async function getCatalogData({
  dispatch,
  catalogBodyFetch,
  token,
}: {
  dispatch: React.Dispatch<any>;
  catalogBodyFetch: IBodyProductDownloadSearch;
  token: string;
}) {
  let valuesCount = 0;
  let values: IFilteredList = {
    total: 0,
    of: 0,
    count: 0,
    data: [] as ICatalogFetchCSVDataPlainSkuResponse[],
  };
  try {
    dispatch({ type: types.SET_IS_LOADING_CATALOG, payload: true });
    const signal = abortCatalogController();
    const catalogFetch = await getCatalogDownloadAPI({
      body: catalogBodyFetch,
      token,
      signal,
    });
    for (const item of catalogFetch.data.data) {
      const transformItem: ICatalogFetchCSVDataPlainSkuResponse = {
        ...item,
        key: valuesCount,
      };
      values.data.push(transformItem);
      valuesCount++;
    }
    values.total = catalogFetch.data.total;
    values.of = catalogFetch.data.of;
    values.count = catalogFetch.data.count;
    dispatch({ type: types.SET_FILTERED_LIST, payload: values ?? [] });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({ type: types.SET_IS_LOADING_CATALOG, payload: false });
    dispatch({ type: types.SET_IS_OPEN_FILTERS, payload: false });
    valuesCount = 0;
    values = {
      total: 0,
      of: 0,
      count: 0,
      data: [] as ICatalogFetchCSVDataPlainSkuResponse[],
    };
  }
}
