/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo, useEffect } from 'react';
import MyBrandsContext from './MyBrandsContext';
import initialMyBrandsState from './initialMyBrandsState';
import userReducer from 'contexts/myBrands/reducer/myBrands.reducer';
import * as action from 'contexts/myBrands/reducer/myBrands.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { TCreateNewBrandProps } from 'interfaces/myBrands.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import { IBrandsBrandState } from 'interfaces/brands.interface';
import { ChildrenProps } from 'interfaces/general.interface';
import { IBannerItemResponse } from 'api/endpoints/general/general.response.interface';
import useContextGeneral from 'hooks/contexts/useContextGeneral';

function MyBrandsProvider(props: ChildrenProps) {
  const [myBrandsState, dispatch] = useReducer(userReducer, initialMyBrandsState);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { t: translate } = useTranslation();
  const { dbUser, company } = useContextUser();
  const { associationStates } = useContextGeneral();

  useEffect(() => {
    (async () => {
      if (
        !company?.id ||
        !isAuthenticated ||
        !associationStates ||
        !company?.country?.id
      ) {
        return false;
      }
      const token = await getAccessTokenSilently();
      if (!token) return false;
      return await action.getMyBrandsAction(
        dispatch,
        company.id,
        token,
        translate,
        associationStates,
        company.country.id
      );
    })();
  }, [company?.id, company?.country?.id, associationStates, isAuthenticated, translate]);

  const getMyBrands = useCallback(async () => {
    if (!company?.id || !associationStates || !company?.country?.id) return false;
    const token = await getAccessTokenSilently();
    if (!token) return false;
    return await action.getMyBrandsAction(
      dispatch,
      company.id,
      token,
      translate,
      associationStates,
      company.country.id
    );
  }, [company?.id, company?.country?.id, associationStates, translate]);

  const addToMyBrand = useCallback(
    async ({
      brand,
      banner,
      setIsOpenDrawer,
    }: {
      brand?: IBrandsBrandState;
      banner?: IBannerItemResponse;
      setIsOpenDrawer?: any;
    }) => {
      if (!company?.id || !associationStates || !company?.country?.id) {
        return Promise.resolve({ ok: false, haveCatalog: false });
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        return Promise.resolve({ ok: false, haveCatalog: false });
      }
      return await action.addToMyBrandAction(
        dispatch,
        brand ?? null,
        banner ?? null,
        company.id,
        token,
        translate,
        setIsOpenDrawer,
        associationStates,
        company.country.id
      );
    },
    [company?.id, company?.country?.id, associationStates, translate]
  );

  const addToMyBrandMultiple = useCallback(
    async ({ brands }: { brands: IBrandsBrandState[] }) => {
      if (!company?.id || !associationStates || !company?.country?.id) return;
      const token = await getAccessTokenSilently();
      if (!token) return;
      return await action.addToMyBrandMultipleAction(
        dispatch,
        myBrandsState.myBrands,
        brands,
        company.id,
        token,
        translate,
        associationStates,
        company.country.id
      );
    },
    [
      company?.id,
      company?.country?.id,
      associationStates,
      myBrandsState.myBrands,
      translate,
    ]
  );

  const removeFromMyBrand = useCallback(
    async ({ associationId }: { associationId: string }) => {
      if (!associationStates) return;
      const token = await getAccessTokenSilently();
      if (!token) return false;
      action.removeFromMyBrandAction(
        dispatch,
        associationId,
        myBrandsState.myBrands,
        associationStates,
        token,
        translate
      );
    },
    [myBrandsState.myBrands, associationStates, translate]
  );

  const removeFromMyBrandsMultiple = useCallback(
    async ({ associationsIds }: { associationsIds: string[] }) => {
      const token = await getAccessTokenSilently();
      if (!token) return false;
      return action.removeFromMyBrandsMultipleAction(
        dispatch,
        associationsIds,
        myBrandsState.myBrands,
        token,
        translate
      ) as Promise<boolean>;
    },
    [myBrandsState.myBrands, translate]
  );

  const createNewBrand = useCallback(
    async ({
      brand,
      formReset,
      setIsOpenDrawer,
    }: {
      brand: TCreateNewBrandProps;
      formReset: any;
      setIsOpenDrawer: (value: boolean) => void;
    }) => {
      if (!company?.id || !associationStates || !dbUser?.name) return false;
      const token = await getAccessTokenSilently();
      if (!token) return false;
      action.createNewBrandAction(
        dispatch,
        brand,
        company.id,
        dbUser.name,
        token,
        translate,
        associationStates,
        setIsOpenDrawer,
        formReset
      );
    },
    [company?.id, associationStates, dbUser?.name, translate]
  );

  const memoProvider = useMemo(
    () => ({
      ...myBrandsState,
      getMyBrands,
      removeFromMyBrand,
      removeFromMyBrandsMultiple,
      addToMyBrand,
      createNewBrand,
      addToMyBrandMultiple,
    }),
    [
      myBrandsState,
      getMyBrands,
      removeFromMyBrand,
      removeFromMyBrandsMultiple,
      addToMyBrand,
      createNewBrand,
      addToMyBrandMultiple,
    ]
  );

  return (
    <MyBrandsContext.Provider value={memoProvider}>
      {props.children}
    </MyBrandsContext.Provider>
  );
}

export default memo(MyBrandsProvider);
