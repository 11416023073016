import { getTenantInfoAPI } from 'api/endpoints/brandFeed/brandFeed.api';
import { ITranslate } from 'interfaces/general.interface';

export async function getBrandFeedAction(
  dispatch: React.Dispatch<any>,
  tenantId: string,
  token: string,
  translate: ITranslate
) {
  try {
    const getTenantInfoFetch = await getTenantInfoAPI({
      tenantId,
      countryId: '45a0b620-9c84-4dc9-a280-ee3d51665fcc',
      token,
    });
    console.log(getTenantInfoFetch);
    if (tenantId) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
}
