import {
  ICSVMappingItem,
  TSalesHistoryItem,
} from 'api/endpoints/sales/sales.response.interface';
import { ICSVMappingKey, IInitialSalesState } from 'interfaces/sales.interface';

const initialSalesState: IInitialSalesState = {
  // Sales history
  getHistory: () => {},
  history: [] as TSalesHistoryItem[],
  isLoadingHistory: false,
  // Remove upload
  removeUpload: () => Promise.resolve(false),
  isLoadingRemoveUpload: {
    isLoading: false,
    uploadId: '',
  },
  // Upload CSV
  csvSelected: {
    papa: {} as Papa.ParseResult<unknown>,
    file: {} as any,
  },
  setCSVSelected: () => {},
  setCSVSelectedCsvData: () => {},
  sendFile: true,
  isLoadingCSVSelected: false,
  setIsLoadingCSVSelected: () => {},
  updatePointsOfSaleMap: () => Promise.resolve(false),
  saveSaleFile: () => Promise.resolve(false),
  // CSV mappings
  csvMappings: [] as ICSVMappingItem[],
  csvMappingKeys: [] as ICSVMappingKey[],
  setCsvMappingKeys: () => {},
  getMappings: () => {},
  isLoadingCSVMappings: false,
  deleteMapping: () => {},
  createMapping: () => {},
};

export default initialSalesState;
