import { IBodyProductSearch } from 'interfaces/catalog.interface';
import { getBasePath } from '../../utils/config';
import {
  IgetCatalogResponse,
  IgetFiltersAPIResponse,
  TgetProductAPIResponse,
} from 'api/endpoints/catalog/catalog.response.interface';

export const getCatalog = async (
  body: IBodyProductSearch,
  token: string,
  signal: AbortSignal | undefined
): Promise<IgetCatalogResponse> => {
  const params = {
    signal: signal,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/product/brand/get_product`, params);
  const data = await response.json();
  return { response, data };
};

export const getCatalogApi = async (
  body: any,
  token: string
): Promise<IgetCatalogResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/product/brand/get_product`, params);
  const data = await response.json();
  return { response, data };
};

export const getFiltersAPI = async (
  brandsId: string[],
  clientId: string,
  countryId: string,
  languageId: string,
  token: string
): Promise<IgetFiltersAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: clientId,
      brand_id: brandsId,
      language_id: languageId,
      country_id: countryId,
    }),
  };
  const response = await fetch(`${getBasePath()}/filter/get_client_filter`, params);
  const data = await response.json();
  return { response, data };
};

export const addLikeAPI = async (
  userId: string,
  productId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: userId,
      product_id: productId,
    }),
  };
  const response = await fetch(
    `${getBasePath()}/favorite/create_favorite_product`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const removeLikeAPI = async (favoriteId: string, token: string): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/favorite/delete_favorite_product?favorite_id=${favoriteId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getProductAPI = async (
  productId: string,
  brandId: string,
  languageId: string,
  userId: string,
  token: string
): Promise<TgetProductAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/product/product_profile?product_id=${productId}&brand_id=${brandId}&language_id=${languageId}&user_id=${userId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};
