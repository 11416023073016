import * as apiUser from 'api/endpoints/user/user.api';
import * as apiCompany from 'api/endpoints/company/company.api';
import * as UserTypes from './user.types';
import { notificationErr } from 'views/components/UI/notification';
import { userCompanyError } from 'utils/errorCodes';
import { messageError, messageSuccess } from 'views/components/UI/message';
import * as key from 'utils/constants';
import {
  ISAdminClientState,
  ISAdminUserClientState,
} from 'interfaces/superAdmin.interface';
import {
  ICompanyUpdateFields,
  TUpdateLanguageProps,
  TUpdateUserPick,
} from 'interfaces/user.interface';
import { TLanguageResponseItem } from 'api/endpoints/general/general.response.interface';
import {
  IloginDbUserAPIResponse,
  IloginDbUserAPIResponseItem,
  IUpdateUserAPIBody,
} from 'api/endpoints/user/user.response.interface';
import { ITranslate } from 'interfaces/general.interface';
import {
  IupdateCompanyDataAPIBody,
  TSocialMedia,
} from 'api/endpoints/company/company.response.interface';
import { LogoutOptions } from '@auth0/auth0-react';

export async function loginUserAction({
  dispatch,
  token,
  emailVerified,
  translate,
  logout,
}: {
  dispatch: React.Dispatch<any>;
  token: string;
  emailVerified: boolean;
  translate: ITranslate;
  logout: (options?: LogoutOptions) => Promise<void>;
}): Promise<boolean> {
  let user: IloginDbUserAPIResponseItem | null = null;
  dispatch({ type: UserTypes.SET_IS_LOADING, payload: true });
  try {
    const userFetch = await apiUser.loginDbUserAPI({ token });
    if (userFetch.response.status === 200) {
      user = userFetch.data;
    }
    return await loginExcep({
      dispatch,
      userFetch,
      logout,
      translate,
      token,
      error: userCompanyError.login_user_001,
    });
  } catch (err) {
    notificationErr(userCompanyError.login_user_002, translate);
    return false;
  } finally {
    dispatch({ type: UserTypes.SET_IS_LOADING, payload: false });
    if (user && user.enabled === false && emailVerified === true) {
      try {
        const body: IUpdateUserAPIBody = {
          filter: { id: user.id },
          update: { enabled: true },
        };
        const verifyFetch = await apiUser.updateDbUserAPI({ body, token });
        if (verifyFetch.response.status === 200) {
          dispatch({
            type: UserTypes.VERIFY_EMAIL,
            payload: true,
          });
        }
      } catch (error) {
        messageError(translate('action_user_login-user-action_verify-error'));
        notificationErr(userCompanyError.login_user_003, translate);
      }
    }
  }
}

export async function updateUserAction({
  dispatch,
  dbUserId,
  user,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  dbUserId: string;
  user: TUpdateUserPick;
  token: string;
  translate: ITranslate;
}): Promise<boolean> {
  const body: IUpdateUserAPIBody = { filter: { id: dbUserId }, update: user };
  try {
    const fetch = await apiUser.updateDbUserAPI({ body, token });

    if (fetch.response.status === 403) {
      messageError(translate('action_user_update-user-action_error-1'));
      notificationErr(userCompanyError.update_user_002, translate);
      return false;
    }

    if (fetch.response.status === 200) {
      messageSuccess(translate('action_user_update-user-action_success'));
      dispatch({
        type: UserTypes.UPDATE_USER,
        payload: user,
      });
      return true;
    }
    return false;
  } catch (err) {
    messageError(translate('action_user_update-user-action_error-2'));
    notificationErr(userCompanyError.update_user_001, translate);
    return false;
  }
}

export async function updateCompanyAction({
  dispatch,
  companyId,
  companyData,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  companyId: string;
  companyData: ICompanyUpdateFields;
  token: string;
  translate: ITranslate;
}): Promise<boolean> {
  const newSocialMedia = companyData.new_social_media;
  delete companyData.new_social_media;
  const body: IupdateCompanyDataAPIBody = {
    filter: { id: companyId },
    update: companyData,
  };

  try {
    const companyFetch = await apiCompany.updateCompanyDataAPI({ body, token });
    if (companyFetch.data[0] === 1) {
      messageSuccess(translate('action_user_update-company-action_success'));
      dispatch({
        type: UserTypes.UPDATE_COMPANY,
        payload: companyData,
      });
      return true;
    }
    if (companyFetch.response.status === 404) {
      messageError(translate('action_user_update-company-action_error-1'));
      notificationErr(userCompanyError.update_company_001, translate);
      return false;
    }
    notificationErr(userCompanyError.update_company_003, translate);
    return false;
  } catch (err) {
    messageError(translate('action_user_update-company-action_error-2'));
    notificationErr(userCompanyError.update_company_002, translate);
    return false;
  } finally {
    if (newSocialMedia) {
      try {
        const socialMediaFetch = await apiCompany.addSocialMediaAPI({
          companyId,
          items: newSocialMedia,
          token,
        });
        if (socialMediaFetch.response.status === 200) {
          for (const item of socialMediaFetch.data) {
            const socialMediaItem: TSocialMedia = {
              id: item.id,
              name: item.name,
              url: item.url,
              client_id: item.client_id,
            };
            dispatch({
              type: UserTypes.ADD_SOCIAL_MEDIA,
              payload: socialMediaItem,
            });
          }
        }
      } catch (err) {
        messageError(translate('action_user_update-company-action_error-3'));
        notificationErr(userCompanyError.add_social_media_001, translate);
      }
    }
  }
}

export async function updateUserAvatarAction({
  dispatch,
  userId,
  image,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  userId: string;
  image: File;
  token: string;
  translate: ITranslate;
}): Promise<boolean> {
  try {
    const update = await apiUser.updateUserAvatarAPI({ userId, image, token });
    if (update.response.status === 200) {
      messageSuccess(translate('action_user_update-user-avatar-action_success'));
      dispatch({
        type: UserTypes.UPDATE_USER_AVATAR,
        payload: update.data,
      });
      return true;
    }
    messageError(translate('action_user_update-user-avatar-action_error-1'));
    notificationErr(userCompanyError.update_user_avatar_002, translate);
    return false;
  } catch (err) {
    messageError(translate('action_user_update-user-avatar-action_error-1'));
    notificationErr(userCompanyError.update_user_avatar_001, translate);
    return false;
  }
}

export async function updateCompanyLogoAction({
  dispatch,
  clientId,
  image,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  clientId: string;
  image: File;
  token: string;
  translate: ITranslate;
}): Promise<boolean> {
  try {
    const update = await apiUser.updateCompanyLogoAPI({ clientId, image, token });
    if (update.response.status === 200) {
      messageSuccess(translate('action_user_update-company-logo-action_success'));
      dispatch({
        type: UserTypes.UPDATE_COMPANY_LOGO,
        payload: update.data,
      });
      return true;
    }
    messageError(translate('action_user_update-company-logo-action_error-1'));
    notificationErr(userCompanyError.update_company_logo_002, translate);
    return false;
  } catch (err) {
    messageError(translate('action_user_update-company-logo-action_error-1'));
    notificationErr(userCompanyError.update_company_logo_001, translate);
    return false;
  }
}

export async function updateLanguageAction({
  dispatch,
  dbUserId,
  language,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  dbUserId: string;
  language: TUpdateLanguageProps;
  token: string;
  translate: ITranslate;
}): Promise<boolean> {
  const body: IUpdateUserAPIBody = {
    filter: { id: dbUserId },
    update: { i18n_lang_id: language.id },
  };
  try {
    const updateLanguageFetch = await apiUser.updateDbUserAPI({ body, token });
    if (updateLanguageFetch.data.updated_user[0] === 1) {
      dispatch({ type: UserTypes.UPDATE_LANGUAGE, payload: language });
      return true;
    }
    notificationErr(userCompanyError.update_language_002, translate);
    return false;
  } catch (err) {
    notificationErr(userCompanyError.update_language_001, translate);
    return false;
  }
}

export async function updateSocialMediaAction({
  dispatch,
  socialMediaState,
  socialMedia,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  socialMediaState: Array<TSocialMedia>;
  socialMedia: TSocialMedia;
  token: string;
  translate: ITranslate;
}): Promise<boolean> {
  const { id, url, name } = socialMedia;
  try {
    const updateFetch = await apiCompany.updateSocialMediaAPI({
      socialMedia,
      token,
    });
    if (updateFetch.response.status === 200 && updateFetch.data[0] === 1) {
      messageSuccess(translate('action_user_update-social-media-action_success'));
      const copyOfSocialMediaState: Array<TSocialMedia> = JSON.parse(
        JSON.stringify(socialMediaState)
      );
      const findSocialMedia = copyOfSocialMediaState.findIndex((el) => el.id === id);
      copyOfSocialMediaState[findSocialMedia].url = url;
      copyOfSocialMediaState[findSocialMedia].name = name;
      dispatch({
        type: UserTypes.SET_SOCIAL_MEDIA,
        payload: copyOfSocialMediaState,
      });
      return true;
    }
    messageError(translate('action_user_update-social-media-action_error'));
    notificationErr(userCompanyError.updateSocialMedia_002, translate);
    return false;
  } catch (err) {
    messageError(translate('action_user_update-social-media-action_error'));
    notificationErr(userCompanyError.updateSocialMedia_001, translate);
    return false;
  }
}

export async function removeSocialMediaAction({
  dispatch,
  socialMediaState,
  socialMediaId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  socialMediaState: Array<TSocialMedia>;
  socialMediaId: string;
  token: string;
  translate: ITranslate;
}): Promise<boolean> {
  try {
    const removeFetch = await apiCompany.removeSocialMediaAPI({ socialMediaId, token });
    if (removeFetch.response.status === 200 && removeFetch.data === 1) {
      const filteredCompany = socialMediaState.filter((el) => el.id !== socialMediaId);
      messageSuccess(translate('action_user_remove-social-media-action_success'));
      dispatch({
        type: UserTypes.SET_SOCIAL_MEDIA,
        payload: filteredCompany,
      });
      return true;
    }
    messageError(translate('action_user_remove-social-media-action_error'));
    notificationErr(userCompanyError.removeSocialMedia_002, translate);
    return false;
  } catch (err) {
    notificationErr(userCompanyError.removeSocialMedia_001, translate);
    messageError(translate('action_user_remove-social-media-action_error'));
    return false;
  }
}

export async function selectCompanyAndUserSuperAdminAction({
  dispatch,
  client,
  user,
  languages,
  translate,
  token,
}: {
  dispatch: React.Dispatch<any>;
  client: ISAdminClientState;
  user: ISAdminUserClientState;
  languages: Array<TLanguageResponseItem>;
  translate: ITranslate;
  token: string;
}): Promise<boolean> {
  dispatch({ type: UserTypes.SET_IS_LOADING, payload: true });
  try {
    const companyFetch = await apiCompany.getCompanyInfoAPI({
      clientId: user.client_id,
      token,
    });
    if (companyFetch.response.status === 200) {
      client.social_media = companyFetch.data.client_social_media;
      dispatch({
        type: UserTypes.LOGIN_COMPANY_USER_SUPER_ADMIN,
        payload: {
          user: user,
          company: client,
          languages,
        },
      });
      return true;
    }
    notificationErr(userCompanyError.selectCompanyAndUserSuperAdmin_002, translate);
    return false;
  } catch (err) {
    notificationErr(userCompanyError.selectCompanyAndUserSuperAdmin_001, translate);
    return false;
  } finally {
    dispatch({ type: UserTypes.SET_IS_LOADING, payload: false });
  }
}

export async function selectCompanySuperAdminAction({
  dispatch,
  client,
  translate,
  token,
}: {
  dispatch: React.Dispatch<any>;
  client: ISAdminClientState;
  translate: ITranslate;
  token: string;
}): Promise<boolean> {
  dispatch({ type: UserTypes.SET_IS_LOADING, payload: true });
  try {
    const companyFetch = await apiCompany.getCompanyInfoAPI({
      clientId: client.id,
      token,
    });
    if (companyFetch.response.status === 200) {
      client.social_media = companyFetch.data.client_social_media;
      dispatch({
        type: UserTypes.LOGIN_COMPANY_SUPER_ADMIN,
        payload: {
          company: client,
        },
      });
      return true;
    }
    notificationErr(userCompanyError.selectCompanySuperAdmin_002, translate);
    return false;
  } catch (err) {
    notificationErr(userCompanyError.selectCompanySuperAdmin_001, translate);
    return false;
  } finally {
    dispatch({ type: UserTypes.SET_IS_LOADING, payload: false });
  }
}

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
const loginExcep = async ({
  dispatch,
  userFetch,
  logout,
  translate,
  token,
  error,
}: {
  dispatch: React.Dispatch<any>;
  userFetch: IloginDbUserAPIResponse;
  logout: (options?: LogoutOptions) => Promise<void>;
  translate: ITranslate;
  token: string;
  error: string;
}): Promise<boolean> => {
  if (!userFetch || !userFetch.response) return false;

  const getUrlToRedirectId = () => {
    if (
      process.env.REACT_APP_NODE_ENV === key.clientLocalKey ||
      !process.env.REACT_APP_NODE_ENV
    ) {
      return key.clientLocal;
    } else if (process.env.REACT_APP_NODE_ENV === key.clientProdKey) {
      return key.clientProd;
    } else if (process.env.REACT_APP_NODE_ENV === key.clientDevKey) {
      return key.clientDev;
    } else if (process.env.REACT_APP_NODE_ENV === key.clientStagingKey) {
      return key.clientStaging;
    }
  };

  if (userFetch?.response?.status === 404) {
    logout();
    return false;
  }

  if (userFetch?.response?.status === 403) {
    window.location.replace(getUrlToRedirectId() as string);
    return false;
  }

  if (userFetch.response?.status === 200) {
    if (userFetch?.data?.role === key.superAdminRole) {
      dispatch({
        type: UserTypes.LOGIN_USER,
        payload: {
          user: userFetch.data,
        },
      });
      return true;
    } else {
      const clientId =
        userFetch?.data?.user_clients &&
        userFetch?.data?.user_clients[0]?.client?.client_id;
      if (!clientId) {
        dispatch({
          type: UserTypes.LOGIN_USER,
          payload: {
            user: userFetch.data,
          },
        });
        return true;
      }
      const companyFetch = await apiCompany.getCompanyInfoAPI({ clientId, token });
      if (clientId) {
        dispatch({
          type: UserTypes.LOGIN_USER,
          payload: {
            user: userFetch.data,
            company: companyFetch.data,
          },
        });
        return true;
      }
    }
    return true;
  } else {
    notificationErr(error, translate);
    return false;
  }
};
