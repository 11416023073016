import { useReducer, useMemo, useCallback, memo, useEffect } from 'react';
import SuperAdminContext from './SuperAdminContext';
import initialSuperAdminState from './initialSuperAdminState';
import superAdminReducer from './reducer/superAdmin.reducer';
import * as action from './reducer/superAdmin.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import {
  IcreateMapFieldProps,
  ICreateQueryProps,
  IcreateUpdateTemplateProps,
  IShopifyQueryItem,
  IShopifyTemplateItem,
  IupdateMapFieldBodyProps,
  IUpdateQueryProps,
} from 'interfaces/superAdmin.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import { superAdminRole } from 'utils/constants';
import { ChildrenProps } from 'interfaces/general.interface';
import { TgetConnectorsAPIBodyProps } from 'api/endpoints/meta/meta.response.interface';
import { IBrandUser } from 'api/endpoints/brandUsers/brandUsers.response.interface';

function SuperAdminProvider(props: ChildrenProps) {
  const [superAdminState, dispatch] = useReducer(
    superAdminReducer,
    initialSuperAdminState
  );
  const { getAccessTokenSilently } = useAuth0();
  const { t: translate } = useTranslation();
  const { dbUser } = useContextUser();

  useEffect(() => {
    if (!dbUser || dbUser.role !== superAdminRole) return;
    (async () => {
      const token = await getAccessTokenSilently();
      action.getClientsAction(dispatch, token, translate);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser?.role]);

  const removeUserClient = useCallback(
    async (clientId: string, userId: string) => {
      action.removeUserClientAction(
        dispatch,
        superAdminState.wholesaleClients,
        clientId,
        userId,
        translate
      );
    },
    [superAdminState.wholesaleClients, translate]
  );

  const addUserClient = useCallback(
    async (clientId: string, user: IBrandUser) => {
      action.addUserClientAction(
        dispatch,
        superAdminState.wholesaleClients,
        clientId,
        user,
        translate
      );
    },
    [superAdminState.wholesaleClients, translate]
  );

  const getConnectors = useCallback(
    async ({ body }: { body: TgetConnectorsAPIBodyProps }) => {
      const token = await getAccessTokenSilently();
      return await action.getConnectorsAction({ dispatch, body, token, translate });
    },
    [getAccessTokenSilently, translate]
  );

  // Map fields //////////////////////////////////////////////////////////////
  const getMapFields = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return await action.getMapFieldsAction({
      dispatch,
      token,
      translate,
    });
  }, [getAccessTokenSilently, translate]);

  const createMapField = useCallback(
    async ({ fieldData }: IcreateMapFieldProps) => {
      const token = await getAccessTokenSilently();
      return await action.createMapFieldAction({
        dispatch,
        fieldData,
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate]
  );

  const updateMapField = useCallback(
    async ({ filter, update }: IupdateMapFieldBodyProps) => {
      const token = await getAccessTokenSilently();
      return await action.updateMapFieldAction({
        dispatch,
        body: { filter, update },
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate]
  );

  const deleteMapField = useCallback(
    async ({ mapFieldId }: { mapFieldId: string }) => {
      const token = await getAccessTokenSilently();
      return await action.deleteMapFieldAction({
        dispatch,
        mapFieldId,
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate]
  );

  // Map fields templates
  const getMapFieldsTemplates = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return await action.getMapFieldsTemplatesAction({
      dispatch,
      token,
      translate,
    });
  }, [getAccessTokenSilently, translate]);

  const createMapFieldsTemplate = useCallback(
    async ({ tag, map_field_ids }: { tag: string; map_field_ids: string[] }) => {
      const token = await getAccessTokenSilently();
      return await action.createMapFieldsTemplateAction({
        dispatch,
        tag,
        map_field_ids,
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate]
  );

  const deleteMapFieldTemplate = useCallback(
    async ({ templateName }: { templateName: string }) => {
      const token = await getAccessTokenSilently();
      return await action.deleteMapFieldTemplateAction({
        dispatch,
        templateName,
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate]
  );

  // Queries Shopify /////////////////////////////////////////////////////////
  const getShopifyQueries = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return await action.getShopifyQueriesAction(dispatch, token, translate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectShopifyQuery = useCallback((query: IShopifyQueryItem) => {
    return action.selectShopifyQueryAction(dispatch, query);
  }, []);

  const createShopifyQuery = useCallback(
    async ({
      tag,
      description,
      query,
      indexable,
      scope,
      audience,
    }: ICreateQueryProps) => {
      const token = await getAccessTokenSilently();
      return await action.createShopifyQueryAction({
        dispatch,
        values: { tag, description, query, indexable, scope, audience },
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const deleteShopifyQuery = useCallback(async ({ queryId }: { queryId: string }) => {
    const token = await getAccessTokenSilently();
    return await action.deleteShopifyQueryAction({
      dispatch,
      queryId,
      token,
      translate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateShopifyQuery = useCallback(
    async ({
      queryId,
      query,
      tag,
      description,
      indexable,
      scope,
      audience,
      showSuccessMsg,
    }: IUpdateQueryProps & { showSuccessMsg?: boolean }) => {
      const token = await getAccessTokenSilently();
      return await action.updateShopifyQueryAction({
        dispatch,
        dataToUpdate: { queryId, query, tag, description, indexable, scope, audience },
        showSuccessMsg,
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Templates Shopify
  const getShopifyTemplates = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return await action.getShopifyTemplatesAction({ dispatch, token, translate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectShopifyTemplate = useCallback(
    ({ template }: { template: IShopifyTemplateItem }) => {
      return action.selectShopifyTemplateAction({ dispatch, template });
    },
    []
  );

  const createUpdateShopifyTemplate = useCallback(
    async ({ tag, queries }: IcreateUpdateTemplateProps) => {
      const token = await getAccessTokenSilently();
      return await action.createUpdateShopifyTemplateAction({
        dispatch,
        values: { tag, queries },
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const deleteShopifyTemplate = useCallback(
    async ({ templateTag }: { templateTag: string }) => {
      const token = await getAccessTokenSilently();
      return await action.deleteShopifyTemplateAction({
        dispatch,
        templateTag,
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const memoProvider = useMemo(
    () => ({
      ...superAdminState,
      removeUserClient,
      addUserClient,
      getConnectors,
      // Map fields
      getMapFields,
      createMapField,
      updateMapField,
      deleteMapField,
      // Map fields templates
      getMapFieldsTemplates,
      createMapFieldsTemplate,
      deleteMapFieldTemplate,
      shopify: {
        ...superAdminState.shopify,
        // Queries
        getShopifyQueries,
        selectShopifyQuery,
        createShopifyQuery,
        deleteShopifyQuery,
        updateShopifyQuery,
        // Templates
        getShopifyTemplates,
        selectShopifyTemplate,
        createUpdateShopifyTemplate,
        deleteShopifyTemplate,
      },
    }),
    [
      superAdminState,
      removeUserClient,
      addUserClient,
      getConnectors,
      // Map fields
      getMapFields,
      createMapField,
      updateMapField,
      deleteMapField,
      // Map fields templates
      getMapFieldsTemplates,
      createMapFieldsTemplate,
      deleteMapFieldTemplate,
      // Queries
      getShopifyQueries,
      selectShopifyQuery,
      createShopifyQuery,
      deleteShopifyQuery,
      updateShopifyQuery,
      // Templates
      getShopifyTemplates,
      selectShopifyTemplate,
      createUpdateShopifyTemplate,
      deleteShopifyTemplate,
    ]
  );

  return (
    <SuperAdminContext.Provider value={memoProvider}>
      {props.children}
    </SuperAdminContext.Provider>
  );
}

export default memo(SuperAdminProvider);
