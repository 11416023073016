import { IBodyMultimediaFetch } from 'interfaces/multimedia.interface';
import { getBasePath } from '../../utils/config';
import { IfindMultimediaAPIResponse } from 'api/endpoints/multimedia/multimedia.response.interface';

export const findMultimediaAPI = async (
  body: IBodyMultimediaFetch,
  signal: AbortSignal | undefined,
  token: string
): Promise<IfindMultimediaAPIResponse> => {
  const params = {
    signal: signal,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/multimedia/find_multimedia`, params);
  const data = await response.json();
  return { response, data };
};
