export const SET_FILTERS_SELECTED = 'SET_FILTERS_SELECTED';
export const SET_IS_LOADING_CATALOG = 'SET_IS_LOADING_CATALOG';
export const SET_FILTERED_LIST = 'SET_FILTERED_LIST';
export const SET_SELECTED_VALUES = 'SET_SELECTED_VALUES';
export const SET_PVI_SLIDER = 'SET_PVI_SLIDER';
export const SET_PVPR_SLIDER = 'SET_PVPR_SLIDER';
export const SET_IS_LOADING_FILTERS = 'SET_IS_LOADING_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_FILTERS_ALERT = 'SET_FILTERS_ALERT';
export const SET_FILTERS_TAGS = 'SET_FILTERS_TAGS';
export const SET_IS_OPEN_FILTERS = 'SET_IS_OPEN_FILTERS';
export const SET_FILTERS_SELECTED_TEMP = 'SET_FILTERS_SELECTED_TEMP';
export const SET_COLUMNS_ORDER = 'SET_COLUMNS_ORDER';
export const SET_DOWNLOADING_STATUS = 'SET_DOWNLOADING_STATUS';
export const SET_IS_DOWNLOADING_ZIP = 'SET_IS_DOWNLOADING_ZIP';
export const SET_DOWNLOAD_IMAGES = 'SET_DOWNLOAD_IMAGES';
export const SET_XHRS_REF = 'SET_XHRS_REF';
export const SET_QUERY_SEARCH = 'SET_QUERY_SEARCH';
export const SET_IMAGE_SELECTION = 'SET_IMAGE_SELECTION';
export const SET_FAVORITES = 'SET_FAVORITES';
export const SET_NOT_DOWNLOADED = 'SET_NOT_DOWNLOADED';
