import {
  IgetBrandUserProfileAPIResponse,
  IgetBrandUsersAPIResponse,
} from 'api/endpoints/brandUsers/brandUsers.response.interface';
import { getBasePath } from '../../utils/config';

export const getBrandUsersAPI = async ({
  brandId,
  token,
}: {
  brandId: string;
  token: string;
}): Promise<IgetBrandUsersAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/client_profile/get_client_users?client_id=${brandId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getBrandUserProfileAPI = async ({
  userId,
  token,
}: {
  userId: string;
  token: string;
}): Promise<IgetBrandUserProfileAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/user/get_user_profile?user_id=${userId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const createBrandUserAPI = async (
  newBrandUser: any,
  token: string
): Promise<any> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newBrandUser),
  };
  const response = await fetch(`${getBasePath()}/user/createUser`, params);
  const data = await response.json();
  return { response, data };
};

export const updateMainContactAPI = async (body: any, token: string): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/user/update_user`, params);
  const data = await response.json();
  return { response, data };
};

export const deleteBrandUserAPI = async (userId: any, token: string): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/user/delete_user?user_id=${userId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const updateRoleAPI = async (
  user_id: string,
  role: string,
  prev_role: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: user_id,
      role: role,
      prev_role: prev_role,
    }),
  };
  const response = await fetch(`${getBasePath()}/user/assign_role`, params);
  const data = await response.json();
  return { response, data };
};
