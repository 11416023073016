import { getBasePath } from '../../utils/config';
import { IGetCompanyInfoAPIResponse } from 'api/endpoints/brandFeed/brandFeed.response.interface';

export const getTenantInfoAPI = async ({
  tenantId,
  countryId,
  token,
}: {
  tenantId: string;
  countryId: string;
  token: string;
}): Promise<IGetCompanyInfoAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/brand_profile/get_tenant_profile?tenant_id=${tenantId}&country_id=${countryId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};
