import { IgetMainContactsAPIResponse } from 'api/endpoints/mainContacts/mainContacts.response.interface';
import { getBasePath } from '../../utils/config';

export const getMainContactsAPI = async (
  clientId: string,
  token: string
): Promise<IgetMainContactsAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/client_profile/get_client_contacts?client_id=${clientId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const createMainContactAPI = async (
  clientId: string,
  contact: any,
  token: string
): Promise<any> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contact),
  };
  const response = await fetch(
    `${getBasePath()}/client_profile/create_client_contact?client_id=${clientId}`,
    params
  );
  const data = await response.json();
  return data;
};

export const updateMainContactAPI = async (
  newContact: any,
  token: string
): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newContact),
  };
  const response = await fetch(
    `${getBasePath()}/client_profile/update_client_contact`,
    params
  );
  const data = await response.json();
  return data;
};

export const removeMainContactAPI = async (
  contactId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/client_profile/delete_client_contact?contact_id=${contactId}`,
    params
  );
  const data = await response.json();
  return data;
};
