import { findMultimediaAPI } from 'api/endpoints/multimedia/multimedia.api';
import * as MTypes from './multimedia.types';
import { notificationErr } from 'views/components/UI/notification';
import { multimediaError } from 'utils/errorCodes';
import { IMyBrand } from 'interfaces/myBrands.interface';
import {
  IBodyMultimediaFetch,
  MultimediaTypes,
  TMediaState,
} from 'interfaces/multimedia.interface';
import { videoType } from 'views/pages/main/multimediaPage/components/constants';
import { ITranslate } from 'interfaces/general.interface';

export async function getMediaAction(
  dispatch: React.Dispatch<any>,
  multimediaState: TMediaState,
  myBrandsIds: string[],
  type: MultimediaTypes,
  signal: AbortSignal | undefined,
  translate: ITranslate,
  token: string
) {
  dispatch({
    type: MTypes.SET_IS_LOADING_MEDIA,
    payload: { type: type, isLoading: true },
  });
  if (!multimediaState.brandSelected.id && myBrandsIds.length === 0) {
    return dispatch({
      type: MTypes.SET_IS_LOADING_MEDIA,
      payload: { type: type, isLoading: false },
    });
  }
  try {
    multimediaState.bodyMultimediaFetch.options.brand_id = multimediaState.brandSelected
      .id
      ? [multimediaState.brandSelected.id]
      : myBrandsIds;
    const mediaFetch = await findMultimediaAPI(
      multimediaState.bodyMultimediaFetch,
      signal,
      token
    );
    const mediaData = mediaFetch.data;
    if (mediaFetch.response.status === 200) {
      return dispatch({
        type: MTypes.GET_MULTIMEDIA,
        payload: { type: type, items: mediaData },
      });
    }
  } catch (err: any) {
    if (err.code !== 20) {
      return notificationErr(multimediaError.get_multimedia_001, translate);
    }
  } finally {
    dispatch({
      type: MTypes.SET_IS_LOADING_MEDIA,
      payload: { type: type, isLoading: false },
    });
  }
}

export async function getMoreMediaAction(
  dispatch: React.Dispatch<any>,
  multimediaState: TMediaState,
  myBrandsIds: string[],
  type: MultimediaTypes,
  signal: AbortSignal | undefined,
  translate: ITranslate,
  token: string
) {
  dispatch({
    type: MTypes.SET_IS_LOADING_MORE_MEDIA,
    payload: { type: type, isLoading: true },
  });
  try {
    multimediaState.bodyMultimediaFetch.options.brand_id = multimediaState.brandSelected
      .id
      ? [multimediaState.brandSelected.id]
      : myBrandsIds;
    const mediaFetch = await findMultimediaAPI(
      multimediaState.bodyMultimediaFetch,
      signal,
      token
    );
    const mediaData = mediaFetch.data;
    if (mediaFetch.response.status === 200) {
      return dispatch({
        type: MTypes.GET_MULTIMEDIA,
        payload: { type: type, items: mediaData },
      });
    }
  } catch (err: any) {
    if (err.code !== 20) {
      return notificationErr(multimediaError.get_more_multimedia_001, translate);
    }
  } finally {
    dispatch({
      type: MTypes.SET_IS_LOADING_MORE_MEDIA,
      payload: { type: type, isLoading: false },
    });
  }
}

export async function getVideosByBrandAction(
  dispatch: React.Dispatch<any>,
  brandId: string,
  signal: AbortSignal | undefined,
  translate: ITranslate,
  token: string
) {
  try {
    const bodyMultimediaVideosFetch: IBodyMultimediaFetch = {
      options: {
        brand_id: [brandId],
        multimedia_type: [videoType],
        search: [],
        limit: 20,
        index: 0,
      },
      filter: {
        tag: [],
        product: [],
        division: [],
        gender: [],
        country: [],
        season: [],
      },
    };
    const mediaFetch = await findMultimediaAPI(bodyMultimediaVideosFetch, signal, token);
    const mediaData = mediaFetch.data;
    if (mediaFetch.response.status === 200) {
      return dispatch({
        type: MTypes.GET_VIDEOS_BY_BRAND,
        payload: mediaData,
      });
    }
  } catch (err: any) {
    if (err.code !== 20) {
      return notificationErr(multimediaError.get_by_brand_001, translate);
    }
  }
}

export async function setBrandSelectedAction(
  dispatch: React.Dispatch<any>,
  brand: IMyBrand | undefined,
  myBrandsIds: string[],
  type: string,
  multimediaState: TMediaState,
  signal: AbortSignal | undefined,
  translate: ITranslate,
  token: string
) {
  dispatch({
    type: MTypes.SET_IS_LOADING_MEDIA,
    payload: { type: type, isLoading: true },
  });
  try {
    multimediaState.bodyMultimediaFetch.options.brand_id = !brand
      ? myBrandsIds
      : [brand.brand.id];
    multimediaState.bodyMultimediaFetch.options.index = 0;
    const mediaFetch = await findMultimediaAPI(
      multimediaState.bodyMultimediaFetch,
      signal,
      token
    );
    const mediaData = mediaFetch.data;
    if (mediaFetch.response.status === 200) {
      if (!brand) {
        dispatch({
          type: MTypes.RESET_BRAND_SELECTED,
          payload: type,
        });
      } else {
        dispatch({
          type: MTypes.SET_BRAND_SELECTED,
          payload: { type: type, brand: brand.brand },
        });
      }
      dispatch({
        type: MTypes.GET_MULTIMEDIA_BY_BRAND,
        payload: { type: type, items: mediaData },
      });
    }
  } catch (err: any) {
    if (err.code !== 20) {
      return notificationErr(multimediaError.get_multimedia_001, translate);
    }
  } finally {
    dispatch({
      type: MTypes.SET_IS_LOADING_MEDIA,
      payload: { type: type, isLoading: false },
    });
  }
}

export async function searchMediaAction(
  dispatch: React.Dispatch<any>,
  multimediaState: TMediaState,
  query: string,
  type: MultimediaTypes,
  signal: AbortSignal | undefined,
  translate: ITranslate,
  token: string
) {
  dispatch({
    type: MTypes.SET_IS_LOADING_MEDIA,
    payload: { type: type, isLoading: true },
  });
  try {
    multimediaState.bodyMultimediaFetch.options.search =
      query.length > 0 ? [`%${query}%`] : [];
    multimediaState.bodyMultimediaFetch.options.index = 0;
    const mediaFetch = await findMultimediaAPI(
      multimediaState.bodyMultimediaFetch,
      signal,
      token
    );
    const mediaData = mediaFetch.data;
    if (mediaFetch.response.status === 200) {
      return dispatch({
        type: MTypes.SEARCH_MEDIA,
        payload: {
          type: type,
          media: mediaData,
          query: query,
        },
      });
    }
  } catch (err: any) {
    if (err.code !== 20) {
      return notificationErr(multimediaError.search_multimedia_001, translate);
    }
  } finally {
    dispatch({
      type: MTypes.SET_IS_LOADING_MEDIA,
      payload: { type: type, isLoading: false },
    });
  }
}
