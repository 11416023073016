import { FileAddTwoTone } from '@ant-design/icons';
import { InputNumber } from 'antd';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useContextSales from 'hooks/contexts/useContextSales';
import * as XLSX from 'xlsx';
import jschardet from 'jschardet';
import { primaryColor } from 'views/components/UI/constants';
import moment from 'moment';
import { messageWarning } from 'views/components/UI/message';

export const isExcelDate = (value: number) => {
  if (typeof value !== 'number') return false;
  const excelEpochStart = new Date(1900, 0, 1).getTime();
  const excelEpochEnd = new Date(9999, 11, 31).getTime();
  const date = XLSX.SSF.parse_date_code(value);
  if (!date) return false;
  const dateTime = new Date(date.y, date.m - 1, date.d).getTime();
  return dateTime >= excelEpochStart && dateTime <= excelEpochEnd;
};

export const xlsxDateFormat = 'DD/MM/YYYY';

function XLSXFileHandler() {
  const { t } = useTranslation();
  const { setCSVSelected } = useContextSales();
  const navigate = useNavigate();
  const [headerRow, setHeaderRow] = useState<number | null>(null);

  const transformArray = useCallback(async (data: any, headerRow: number) => {
    const headers = data[headerRow];
    const result: { [key: string]: string | number }[] = [];
    for (let i = headerRow + 1; i < data.length; i++) {
      const values = data[i];
      const rowObject: { [key: string]: string | number } = {};
      for (const [index, header] of headers.entries()) {
        let value = values[index];
        if (header?.toLowerCase().includes('date')) {
          if (isExcelDate(value)) {
            const date = XLSX.SSF.parse_date_code(value);
            value = moment(new Date(date.y, date.m - 1, date.d)).format(xlsxDateFormat);
          }
        }
        rowObject[header] = value;
      }
      result.push(rowObject);
    }
    return result;
  }, []);

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        if (file.size > 50 * 1024 * 1024) {
          // 50MB in bytes
          messageWarning(t('sales_add-sales-csv_file-limit'));
          return;
        }
        const reader = new FileReader();
        reader.onload = async (e) => {
          const binaryStr: any = e.target?.result;
          const csvResult = binaryStr.split(/\r|\n|\r\n/);
          const detected = jschardet.detectAll(csvResult.toString());
          const encoding = detected[0].encoding;
          const workbook = XLSX.read(binaryStr, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let jsonData = (
            XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as any[][]
          ).filter((row) =>
            row.some((cell) => cell !== null && cell !== undefined && cell !== '')
          );
          const delimiter = ';';
          const linebreak = '\r\n';
          const fields = jsonData[headerRow!];

          const meta = {
            delimiter,
            linebreak,
            aborted: false,
            truncated: false,
            cursor: jsonData.length,
            fields: fields as string[],
            encoding: encoding || 'utf-8',
          };

          const transformedData = await transformArray(jsonData, headerRow!);

          setCSVSelected({
            csvData: {
              data: transformedData,
              meta,
              errors: [],
            },
            file,
            navigate,
            sendFile: true,
          });
        };
        reader.readAsBinaryString(file);
      }
    },
    [headerRow, navigate, setCSVSelected, t, transformArray]
  );

  const triggerFileInput = useCallback(() => {
    document.getElementById('fileInput')?.click();
  }, []);

  return (
    <div
      style={{ border: `1px solid grey`, padding: 10, borderRadius: 10, maxWidth: 320 }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <p style={{ fontSize: '1rem', lineHeight: '1.5rem' }}>
          {t('sales_add-sales-xlsx_select-row_description')}
        </p>
        <InputNumber
          min={1}
          max={20}
          onChange={(value) => setHeaderRow(value ? Number(value) - 1 : null)}
          placeholder={t('sales_add-sales-xlsx_select-row_input-placeholder')}
          style={{ width: 90, margin: '0 auto', marginBottom: 15 }}
        />
      </div>
      <div
        className="input-csv"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F3F3F3',
          cursor: headerRow === null ? 'not-allowed' : 'pointer',
          width: 300,
        }}
        onClick={headerRow !== null ? triggerFileInput : undefined}
      >
        <div className="upload-csv">
          <FileAddTwoTone
            style={{ fontSize: '2rem' }}
            twoToneColor={
              headerRow === null ? 'rgba(37, 125, 140, .6)' : 'rgba(37, 125, 140, 1)'
            }
          />
          <p
            style={{
              color: headerRow === null ? 'rgba(37, 125, 140, .6)' : primaryColor,
            }}
          >
            {t('sales_add-sales-xlsx-input-title')}
          </p>
        </div>
      </div>
      <div>
        <input
          id="fileInput"
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
          disabled={headerRow === null}
        />
      </div>
    </div>
  );
}

export default memo(XLSXFileHandler);
