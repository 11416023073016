import * as NotificationsTypes from './notifications.types';
import { ITranslate } from 'interfaces/general.interface';
// import { myBrandsError } from 'utils/errorCodes';
// import { notificationError } from 'views/components/UI/notification';
// import { messageError, messageSuccess, messageWarning } from 'views/components/UI/message';

export async function getNotificationsAction(
  dispatch: React.Dispatch<any>,
  translate: ITranslate
) {
  return dispatch({
    type: NotificationsTypes.SET_NOTIFICATIONS,
  });
}

export async function markAsReadedAction(
  dispatch: React.Dispatch<any>,
  type: string,
  notificationId: string,
  read: boolean,
  translate: ITranslate
) {
  if (type === 'notification') {
    return dispatch({
      type: NotificationsTypes.MARK_AS_READED_NOTIFICATION,
      payload: {
        id: notificationId,
        readed: read,
      },
    });
  }
  // if (type === 'alert') {
  //   return dispatch({
  //     type: NotificationsTypes.MARK_AS_READED_ALERT,
  //     payload: {
  //       id: notificationId,
  //       readed: read,
  //     },
  //   });
  // }
}
