import * as superAdminInterface from 'interfaces/superAdmin.interface';
import * as SuperAdminTypes from './superAdmin.types';
import * as superadminApi from 'api/endpoints/superAdmin/superAdmin.api';
import moment from 'moment';
import { TgetConnectorsAPIBodyProps } from 'api/endpoints/meta/meta.response.interface';
import { getConnectionsAPI } from 'api/endpoints/meta/meta.api';
import {
  messageError,
  messageSuccess,
  messageWarning,
} from 'views/components/UI/message';
import { notificationErr } from 'views/components/UI/notification';
import { superAdminError } from 'utils/errorCodes';
import { IBrandUser } from 'api/endpoints/brandUsers/brandUsers.response.interface';
import { ITranslate } from 'interfaces/general.interface';

export async function getClientsAction(
  dispatch: React.Dispatch<any>,
  token: string,
  translate: ITranslate
) {
  try {
    const [getWholesaleClientsFetch, getBrandClientsFetch] = await Promise.all([
      superadminApi.getClientsAPI(token, 'client'),
      superadminApi.getClientsAPI(token, 'tenant'),
    ]);
    if (
      getWholesaleClientsFetch.response.status === 200 &&
      getBrandClientsFetch.response.status === 200
    ) {
      getWholesaleClientsFetch.data.forEach((item, index) => {
        getWholesaleClientsFetch.data[index].logo = item.logo
          ? `${item.logo}?ts=${moment().unix()}`
          : '';
      });
      dispatch({
        type: SuperAdminTypes.SET_CLIENTS,
        payload: {
          wholesale: getWholesaleClientsFetch.data,
          brand: getBrandClientsFetch.data,
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export async function removeUserClientAction(
  dispatch: React.Dispatch<any>,
  clients: superAdminInterface.ISAdminClientState[],
  clientId: string,
  userId: string,
  translate: ITranslate
) {
  const findIndexClient = clients.findIndex((client) => client.id === clientId);
  if (findIndexClient !== -1) {
    const userClients = clients[findIndexClient].user_clients.filter(
      (user) => user.user_id !== userId
    );
    clients[findIndexClient].user_clients = userClients;
    dispatch({
      type: SuperAdminTypes.SET_CLIENTS,
      payload: clients,
    });
  }
}

export async function addUserClientAction(
  dispatch: React.Dispatch<any>,
  clients: superAdminInterface.ISAdminClientState[],
  clientId: string,
  user: IBrandUser,
  translate: ITranslate
) {
  const newUser: superAdminInterface.ISAdminUserClientState = {
    id: user.key.toString(),
    user_id: user.key.toString(),
    client_id: clientId,
    createdAt: undefined,
    updatedAt: undefined,
    user: {
      id: user.key.toString(),
      auth_id: user.auth_id,
      username: user.username,
      name: user.name,
      surname: user.surname,
      tel_prefix: '',
      tel_sufix: '',
      profile_image: '',
      i18n_lang_id: user.i18n_lang_id,
      i18n_lang_code: 'es',
      role: user.role,
      dashboard: '',
      sector: user.sector,
      signed: user.signed,
      first_login: user.first_login,
      enabled: user.enabled,
      lastLoging: user.lastLoging,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
    },
  };
  const findIndexClient = clients.findIndex((client) => client.id === clientId);
  if (findIndexClient !== -1) {
    clients[findIndexClient].user_clients.push(newUser);
    dispatch({
      type: SuperAdminTypes.SET_CLIENTS,
      payload: clients,
    });
  }
}

export async function getConnectorsAction({
  dispatch,
  body,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  body: TgetConnectorsAPIBodyProps;
  token: string;
  translate: ITranslate;
}) {
  try {
    const getConnectionsFetch = await getConnectionsAPI({ body, token });
    if (getConnectionsFetch.response.status === 200) {
      dispatch({
        type: SuperAdminTypes.SET_CONNECTORS,
        payload: getConnectionsFetch.data,
      });
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// Map fields
export async function getMapFieldsAction({
  dispatch,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: SuperAdminTypes.IS_LOADING_GET_MAP_FIELDS,
    payload: true,
  });
  try {
    const getMapFieldsFetch = await superadminApi.getMapFieldsAPI({ token });
    if (
      getMapFieldsFetch.response.status === 200 ||
      getMapFieldsFetch.response.status === 201
    ) {
      dispatch({
        type: SuperAdminTypes.GET_MAP_FIELDS,
        payload: getMapFieldsFetch.data.data,
      });
      return true;
    }
    return false;
  } catch (error) {
    messageError('Error del servidor al obtener los campos del mapa');
    notificationErr(superAdminError.get_map_fields_001, translate);
    return false;
  } finally {
    dispatch({
      type: SuperAdminTypes.IS_LOADING_GET_MAP_FIELDS,
      payload: false,
    });
  }
}

export async function createMapFieldAction({
  dispatch,
  fieldData,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  fieldData:
    | superAdminInterface.MapFieldReplaceKeyOrValueBodyProps
    | superAdminInterface.MapFieldSearchBodyProps
    | superAdminInterface.MapFieldSubstringBodyProps
    | superAdminInterface.MapFieldPatternBodyProps
    | superAdminInterface.MapFieldDateBodyProps
    | superAdminInterface.MapFieldLoopBodyProps
    | superAdminInterface.MapFieldDeleteBodyProps
    | superAdminInterface.MapFieldCleanBodyProps;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: SuperAdminTypes.IS_LOADING_CREATE_MAP_FIELD,
    payload: true,
  });
  try {
    const createMapFieldFetch = await superadminApi.createMapFieldAPI({
      mapField: fieldData,
      token,
    });
    if (
      createMapFieldFetch.response.status === 200 ||
      createMapFieldFetch.response.status === 201
    ) {
      messageSuccess('Instrucción creada con éxito');
      dispatch({
        type: SuperAdminTypes.CREATE_MAP_FIELD,
        payload: createMapFieldFetch.data.data[0],
      });
      return true;
    }
    messageError('Error al crear el campo');
    notificationErr(superAdminError.create_map_field_002, translate);
    return false;
  } catch (error) {
    messageError('Error del servidor al crear el campo');
    notificationErr(superAdminError.create_map_field_001, translate);
    return false;
  } finally {
    dispatch({
      type: SuperAdminTypes.IS_LOADING_CREATE_MAP_FIELD,
      payload: false,
    });
  }
}

export async function updateMapFieldAction({
  dispatch,
  body,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  body: superAdminInterface.IupdateMapFieldBodyProps;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: SuperAdminTypes.IS_LOADING_UPDATE_MAP_FIELD,
    payload: true,
  });
  try {
    const updateMapFieldFetch = await superadminApi.updateMapFieldAPI({
      body: body,
      token,
    });
    if (
      updateMapFieldFetch.response.status === 200 ||
      updateMapFieldFetch.response.status === 201
    ) {
      messageSuccess('Campo actualizado con éxito');
      dispatch({
        type: SuperAdminTypes.UPDATE_MAP_FIELD,
        payload: {
          id: body.filter.id,
          data: body.update,
        },
      });
      return true;
    }
    messageError('Error al actualizar el campo');
    notificationErr(superAdminError.update_map_field_002, translate);
    return false;
  } catch (error) {
    messageError('Error del servidor al actualizar el campo');
    notificationErr(superAdminError.update_map_field_001, translate);
    return false;
  } finally {
    dispatch({
      type: SuperAdminTypes.IS_LOADING_UPDATE_MAP_FIELD,
      payload: false,
    });
  }
}

export async function deleteMapFieldAction({
  dispatch,
  mapFieldId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  mapFieldId: string;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: SuperAdminTypes.IS_LOADING_DELETE_MAP_FIELD,
    payload: {
      mapFieldId,
      loading: true,
    },
  });
  try {
    const deleteMapFieldFetch = await superadminApi.deleteMapFieldAPI({
      mapFieldId,
      token,
    });
    if (deleteMapFieldFetch.response.status === 409) {
      return messageWarning('No se puede eliminar el campo porque está en uso');
    }
    if (
      deleteMapFieldFetch.response.status === 200 ||
      deleteMapFieldFetch.response.status === 201
    ) {
      dispatch({
        type: SuperAdminTypes.DELETE_MAP_FIELD,
        payload: mapFieldId,
      });
      messageSuccess('Campo eliminado con éxito');
      return true;
    }
    messageError('Error al eliminar el campo');
    notificationErr(superAdminError.delete_map_field_002, translate);
    return false;
  } catch (error) {
    messageError('Error del servidor al eliminar el campo');
    notificationErr(superAdminError.delete_map_field_001, translate);
    return false;
  } finally {
    dispatch({
      type: SuperAdminTypes.IS_LOADING_DELETE_MAP_FIELD,
      payload: {
        mapFieldId: '',
        loading: false,
      },
    });
  }
}

// Map fields templates ///////////////////////////////////////////////
export async function getMapFieldsTemplatesAction({
  dispatch,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: SuperAdminTypes.IS_LOADING_GET_MAP_FIELDS_TEMPLATES,
    payload: true,
  });
  try {
    const getMapFieldsFetch = await superadminApi.getMapFieldsTemplatesAPI({ token });
    if (
      getMapFieldsFetch.response.status === 200 ||
      getMapFieldsFetch.response.status === 201
    ) {
      dispatch({
        type: SuperAdminTypes.GET_MAP_FIELDS_TEMPLATES,
        payload: Object.entries(getMapFieldsFetch.data.data).map(
          ([templateName, rest]) => {
            return {
              templateName,
              mapFields: rest.map((item) => item),
            };
          }
        ),
      });
      return true;
    }
    return false;
  } catch (error) {
    messageError('Error del servidor al obtener los campos del mapa');
    notificationErr(superAdminError.get_map_fields_templates_001, translate);
    return false;
  } finally {
    dispatch({
      type: SuperAdminTypes.IS_LOADING_GET_MAP_FIELDS_TEMPLATES,
      payload: false,
    });
  }
}

export async function createMapFieldsTemplateAction({
  dispatch,
  tag,
  map_field_ids,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  tag: string;
  map_field_ids: string[];
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: SuperAdminTypes.IS_LOADING_CREATE_MAP_FIELDS_TEMPLATE,
    payload: true,
  });
  try {
    const createMapFieldsTemplateFetch = await superadminApi.createMapFieldTemplateAPI({
      tag,
      map_field_ids,
      token,
    });
    if (
      createMapFieldsTemplateFetch.response.status === 200 ||
      createMapFieldsTemplateFetch.response.status === 201
    ) {
      messageSuccess('Plantilla creada con éxito');
      await getMapFieldsTemplatesAction({ dispatch, token, translate });
      return true;
    }
    messageError('Error al crear la plantilla');
    notificationErr(superAdminError.create_map_fields_template_002, translate);
    return false;
  } catch (error) {
    messageError('Error del servidor al crear la plantilla');
    notificationErr(superAdminError.create_map_fields_template_001, translate);
    return false;
  } finally {
    dispatch({
      type: SuperAdminTypes.IS_LOADING_CREATE_MAP_FIELDS_TEMPLATE,
      payload: false,
    });
  }
}

export async function deleteMapFieldTemplateAction({
  dispatch,
  templateName,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  templateName: string;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: SuperAdminTypes.IS_LOADING_DELETE_MAP_FIELD_TEMPLATE,
    payload: {
      templateName,
      loading: true,
    },
  });
  try {
    const deleteMapFieldTemplateFetch = await superadminApi.deleteMapFieldTemplateAPI({
      templateName,
      token,
    });
    if (deleteMapFieldTemplateFetch.response.status === 409) {
      return messageWarning('No se puede eliminar el campo porque está en uso');
    }
    if (
      deleteMapFieldTemplateFetch.response.status === 200 ||
      deleteMapFieldTemplateFetch.response.status === 201
    ) {
      dispatch({
        type: SuperAdminTypes.DELETE_MAP_FIELD_TEMPLATE,
        payload: templateName,
      });
      messageSuccess('Plantilla eliminada con éxito');
      return true;
    }
    messageError('Error al eliminar la plantilla');
    notificationErr(superAdminError.delete_map_field_template_002, translate);
    return false;
  } catch (error) {
    messageError('Error del servidor al eliminar la plantilla');
    notificationErr(superAdminError.delete_map_field_template_001, translate);
    return false;
  } finally {
    dispatch({
      type: SuperAdminTypes.IS_LOADING_DELETE_MAP_FIELD_TEMPLATE,
      payload: {
        templateName: '',
        loading: false,
      },
    });
  }
}

// Queries Shopify
export async function getShopifyQueriesAction(
  dispatch: React.Dispatch<any>,
  token: string,
  translate: ITranslate
) {
  dispatch({ type: SuperAdminTypes.SET_IS_LOADING_QUERIES, payload: true });
  try {
    const getQueriesFetch = await superadminApi.getShopifyQueriesAPI({ token });
    if (getQueriesFetch.response.status === 200) {
      dispatch({
        type: SuperAdminTypes.SET_QUERIES,
        payload: getQueriesFetch.data.data,
      });
      return true;
    }
    return false;
  } catch (error) {
    messageError(translate('action_meta_get-queries_error'));
    notificationErr(superAdminError.get_shopify_queries_001, translate);
    return false;
  } finally {
    dispatch({ type: SuperAdminTypes.SET_IS_LOADING_QUERIES, payload: false });
  }
}

export async function selectShopifyQueryAction(
  dispatch: React.Dispatch<any>,
  query: superAdminInterface.IShopifyQueryItem
) {
  dispatch({
    type: SuperAdminTypes.SET_QUERY_SELECTED,
    payload: query,
  });
}

type TcreateQueryActionProps = {
  dispatch: React.Dispatch<any>;
  values: superAdminInterface.ICreateQueryProps;
  token: string;
  translate: ITranslate;
};
export async function createShopifyQueryAction({
  dispatch,
  values,
  token,
  translate,
}: TcreateQueryActionProps) {
  dispatch({ type: SuperAdminTypes.SET_IS_LOADING_CREATE_QUERY, payload: true });
  if (!values.scope || values.scope === '' || values.scope === undefined) {
    delete values.scope;
  }
  if (!values.audience || values.audience === undefined) {
    delete values.audience;
  }
  try {
    const createQueryFetch = await superadminApi.createShopifyQueryAPI({
      body: values,
      token,
    });
    if (createQueryFetch.response.status === 409) {
      messageWarning('Ya existe una query con ese nombre, por favor elige otro');
      return false;
    }
    if (
      createQueryFetch.response.status === 200 ||
      createQueryFetch.response.status === 201
    ) {
      messageSuccess('Query creada con éxito');
      dispatch({
        type: SuperAdminTypes.ADD_NEW_QUERY,
        payload: createQueryFetch.data,
      });
      return true;
    }
    messageError('Error al crear la query');
    notificationErr(superAdminError.create_shopify_query_002, translate);
    return false;
  } catch (error) {
    messageError('Error del servidor al crear la query');
    notificationErr(superAdminError.create_shopify_query_001, translate);
    return false;
  } finally {
    dispatch({ type: SuperAdminTypes.SET_IS_LOADING_CREATE_QUERY, payload: false });
  }
}

export async function deleteShopifyQueryAction({
  dispatch,
  queryId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  queryId: string;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: SuperAdminTypes.SET_IS_LOADING_DELETE_QUERY,
    payload: { loading: true, queryId },
  });
  try {
    const deleteQueryFetch = await superadminApi.deleteShopifyQueryAPI({
      queryId,
      token,
    });
    if (deleteQueryFetch.response.status === 200) {
      messageSuccess('Query eliminada con éxito');
      dispatch({
        type: SuperAdminTypes.DELETE_QUERY,
        payload: queryId,
      });
      return true;
    }
    messageError('Error al eliminar la query');
    notificationErr(superAdminError.delete_shopify_query_002, translate);
    return false;
  } catch (error) {
    messageError('Error del servidor al eliminar la query');
    notificationErr(superAdminError.delete_shopify_query_001, translate);
    return false;
  } finally {
    dispatch({
      type: SuperAdminTypes.SET_IS_LOADING_DELETE_QUERY,
      payload: { loading: false, queryId: '' },
    });
  }
}

export async function updateShopifyQueryAction({
  dispatch,
  dataToUpdate,
  showSuccessMsg = true,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  dataToUpdate: superAdminInterface.IUpdateQueryProps;
  showSuccessMsg?: boolean;
  token: string;
  translate: ITranslate;
}) {
  dispatch({ type: SuperAdminTypes.SET_IS_LOADING_UPDATE_QUERY, payload: true });
  if (
    !dataToUpdate.scope ||
    dataToUpdate.scope === '' ||
    dataToUpdate.scope === undefined
  ) {
    delete dataToUpdate.scope;
  }
  if (!dataToUpdate.audience || dataToUpdate.audience === undefined) {
    delete dataToUpdate.audience;
  }
  try {
    const updateQueryFetch = await superadminApi.updateShopifyQueryAPI({
      dataToUpdate,
      token,
    });
    if (updateQueryFetch.response.status === 200) {
      showSuccessMsg &&
        showSuccessMsg === true &&
        messageSuccess('Query actualizada con éxito');
      dispatch({
        type: SuperAdminTypes.UPDATE_QUERY,
        payload: {
          id: dataToUpdate.queryId,
          data: {
            tag: dataToUpdate.tag,
            description: dataToUpdate.description,
            query: dataToUpdate.query,
            indexable: dataToUpdate.indexable,
            scope: dataToUpdate.scope,
          },
        },
      });
      return true;
    }
    messageError('Error al actualizar la query');
    notificationErr(superAdminError.update_shopify_query_002, translate);
    return false;
  } catch (error) {
    messageError('Error del servidor al actualizar la query');
    notificationErr(superAdminError.update_shopify_query_001, translate);
    return false;
  } finally {
    dispatch({ type: SuperAdminTypes.SET_IS_LOADING_UPDATE_QUERY, payload: false });
  }
}

// Templates shopify
export async function getShopifyTemplatesAction({
  dispatch,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  token: string;
  translate: ITranslate;
}) {
  dispatch({ type: SuperAdminTypes.SET_IS_LOADING_TEMPLATES, payload: true });
  try {
    const getTemplatesFetch = await superadminApi.getTemplatesAPI({ token });
    if (getTemplatesFetch.response.status === 200) {
      const formattedData: superAdminInterface.IShopifyTemplateItem[] = Object.keys(
        getTemplatesFetch.data.data
      ).map((key) => {
        return {
          tag: key,
          queries: getTemplatesFetch.data.data[key].map(
            (item: { id: string }) => item.id
          ),
        };
      });
      dispatch({
        type: SuperAdminTypes.SET_TEMPLATES,
        payload: formattedData,
      });
      return true;
    }
    return false;
  } catch (error) {
    messageError('Error del servidor al obtener las plantillas');
    notificationErr(superAdminError.get_shopify_templates_001, translate);
    return false;
  } finally {
    dispatch({ type: SuperAdminTypes.SET_IS_LOADING_TEMPLATES, payload: false });
  }
}

export function selectShopifyTemplateAction({
  dispatch,
  template,
}: {
  dispatch: React.Dispatch<any>;
  template: superAdminInterface.IShopifyTemplateItem;
}) {
  dispatch({
    type: SuperAdminTypes.SET_TEMPLATE_SELECTED,
    payload: template,
  });
}

export async function createUpdateShopifyTemplateAction({
  dispatch,
  values,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  values: superAdminInterface.IShopifyTemplateItem;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: SuperAdminTypes.SET_IS_LOADING_CREATE_UPDATE_TEMPLATE,
    payload: true,
  });
  try {
    const createUpdateTemplateFetch = await superadminApi.createShopifyTemplateAPI({
      body: values,
      token,
    });
    if (createUpdateTemplateFetch.response.status === 409) {
      messageWarning('Ya existe una plantilla con ese nombre, por favor elige otro');
      return false;
    }
    if (
      createUpdateTemplateFetch.response.status === 200 ||
      createUpdateTemplateFetch.response.status === 201
    ) {
      messageSuccess('Plantilla creada con éxito');
      dispatch({
        type: SuperAdminTypes.CREATE_UPDATE_TEMPLATE,
        payload: {
          tag: values.tag,
          data: values,
        },
      });
      return true;
    }
    messageError('Error al crear la plantilla');
    notificationErr(superAdminError.create_update_shopify_template_002, translate);
    return false;
  } catch (error) {
    messageError('Error del servidor al crear la plantilla');
    notificationErr(superAdminError.create_update_shopify_template_001, translate);
    return false;
  } finally {
    dispatch({
      type: SuperAdminTypes.SET_IS_LOADING_CREATE_UPDATE_TEMPLATE,
      payload: false,
    });
  }
}

export async function deleteShopifyTemplateAction({
  dispatch,
  templateTag,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  templateTag: string;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: SuperAdminTypes.SET_IS_LOADING_DELETE_TEMPLATE,
    payload: { loading: true, tag: templateTag },
  });
  try {
    const deleteTemplateFetch = await superadminApi.deleteShopifyTemplateAPI({
      templateTag,
      token,
    });
    if (deleteTemplateFetch.response.status === 200) {
      messageSuccess('Plantilla eliminada con éxito');
      dispatch({
        type: SuperAdminTypes.DELETE_TEMPLATE,
        payload: templateTag,
      });
      return true;
    }
    messageError('Error al eliminar la plantilla');
    notificationErr(superAdminError.delete_shopify_template_002, translate);
    return false;
  } catch (error) {
    messageError('Error del servidor al eliminar la plantilla');
    notificationErr(superAdminError.delete_shopify_template_001, translate);
    return false;
  } finally {
    dispatch({
      type: SuperAdminTypes.SET_IS_LOADING_DELETE_TEMPLATE,
      payload: { loading: false, tag: '' },
    });
  }
}
